import React, { useMemo } from "react";
import ScheduledAlarmsPanel from "./components/ScheduledAlarmsPanel";
import SelfiePromptCard from "./components/SelfiePromptCard";
import SetAlarm from "./components/SetAlarm";
import { useDashbaord } from "state/dashboard/hooks";
import { useAlarms } from "hooks/alarms/useAlarms";
import AppLayout from "components/Layouts/App";
import EditAlarmModal from "./components/EditAlarmModal";
import platform from "platform";

const Dashboard = () => {
  const { alarms, selectedAlarm, setSelectedAlarm } = useDashbaord();
  const { createAlarm, updateAlarm, deleteAlarm } = useAlarms();
  const scheduledDays = useMemo(() => {
    return alarms.map(({ day }) => day);
  }, [alarms]);

  const isDeviceIPhone = platform.product === "iPhone";

  return (
    <AppLayout>
      <div className="flex flex-col justify-center w-full max-w-[650px] gap-y-7 mx-auto">
        {/* <UpcomingAlarmTile /> */}
        {alarms.length > 0 && <SelfiePromptCard />}
        <div className="flex items-center">
          <h1 className="text-main text-xl mobile:text-base typography">
            {alarms.length > 0 ? "Your Schedules" : "Nothing Scheduled"}
          </h1>
          {alarms.length > 0 ?
            <span className='text-xs ml-3 italic'>(Pacific Time Zone)</span>
            : null}
        </div>
        {alarms.length > 0 ?
          <p className="text-center font-bold bg-[#FFFF00]"><i>Important: turn on your phone’s ring mode and volume in order to hear the wake up call!</i></p>
          : null
        }
        <div
          className={`mobile:overflow-auto ${isDeviceIPhone
              ? "mobile:h-[calc(100vh_-_360px)]"
              : "mobile:h-[calc(100vh_-_300px)]"
            }`}
        >
          <ScheduledAlarmsPanel
            alarms={alarms}
            setSelectedAlarm={setSelectedAlarm}
          />
          {/*if all days are scheduled we don't need to show set alarm anymore*/}
          {scheduledDays.length !== 6 && (
            <SetAlarm createAlarm={createAlarm} scheduledDays={scheduledDays} />
          )}
        </div>
      </div>
      <EditAlarmModal
        alarm={selectedAlarm}
        setSelectedAlarm={setSelectedAlarm}
        updateAlarm={updateAlarm}
        deleteAlarm={deleteAlarm}
      />
    </AppLayout>
  );
};

export default Dashboard;
