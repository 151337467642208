import { combineReducers } from "@reduxjs/toolkit";
import { configureStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import UserReducer from "state/user";
import AuthReducer from "state/auth";
import LoadingReducer from "state/loading";
import DashboardReducer from "state/dashboard";
const rootReducer = combineReducers({
  user: UserReducer,
  auth: AuthReducer,
  loading: LoadingReducer,
  dashboard: DashboardReducer,

  // Add other reducers here if you have any
});

const store = configureStore({
  reducer: rootReducer,
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export const useAppDispatch: () => AppDispatch = useDispatch;
export type AppDispatch = typeof store.dispatch;
