import { api } from "config/api";
import { useCallback } from "react";
import { toast } from "react-toastify";
import { useDashbaord } from "state/dashboard/hooks";
import { TAlarm } from "state/dashboard/types";
import { useLoading } from "state/loading/hooks";
import { LOADINGSTATE } from "state/loading/types";
import { TCreateAlarmPayload, TUpdateAlarmPayload } from "types/alarm";
import { parseResponse } from "utils/alarm";
import { handleServerError } from "utils/error";

export const useAlarms = () => {
  const { setIsLoading } = useLoading();
  const { setAlarms } = useDashbaord();

  const getUserAlarms = useCallback(async () => {
    try {
      setIsLoading({ [LOADINGSTATE.ALARMS]: true });
      const { data } = await api.get("alarms/alarm_groups/");
      if (!data) return;
      if (!data.results) return;
      const alarms: TAlarm[] = parseResponse(data.results);
      // console.log("alarms", alarms);
      setAlarms(alarms);
    } catch (e) {
      handleServerError(e);
    } finally {
      setIsLoading({ [LOADINGSTATE.ALARMS]: false });
    }
  }, [setIsLoading, setAlarms]);

  const createAlarm = useCallback(
    async (payload: TCreateAlarmPayload) => {
      try {
        setIsLoading({ [LOADINGSTATE.ALARMS]: true });
        const { data } = await api.post("alarms/alarm_groups/", {
          title: "any",
          alarms: [payload],
        });
        if (!data) return;
        toast.success("Alarm created successfully");
        getUserAlarms();
      } catch (e) {
        handleServerError(e);
      } finally {
        setIsLoading({ [LOADINGSTATE.ALARMS]: false });
      }
    },
    [setIsLoading, getUserAlarms]
  );
  const updateAlarm = useCallback(
    async (payload: TUpdateAlarmPayload) => {
      try {
        setIsLoading({ [LOADINGSTATE.ALARMS]: true });
        const { data } = await api.put(`alarms/alarm_groups/${payload.id}/`, {
          title: "any",
          alarms: [
            {
              day: payload.day,
              time: payload.time,
            },
          ],
        });
        if (!data) return;
        toast.success("Alarm updated successfully");
        getUserAlarms();
      } catch (e) {
        handleServerError(e);
      } finally {
        setIsLoading({ [LOADINGSTATE.ALARMS]: false });
      }
    },
    [setIsLoading, getUserAlarms]
  );

  const deleteAlarm = useCallback(
    async (id: number) => {
      try {
        setIsLoading({ [LOADINGSTATE.ALARMS]: true });
        await api.delete(`alarms/alarm_groups/${id}/`);
        getUserAlarms();
        toast.success("Alarm removed successfully");
      } catch (e) {
        handleServerError(e);
      } finally {
        setIsLoading({ [LOADINGSTATE.ALARMS]: false });
      }
    },
    [setIsLoading, getUserAlarms]
  );
  return {
    getUserAlarms,
    updateAlarm,
    createAlarm,
    deleteAlarm,
  };
};
