import { createSlice } from "@reduxjs/toolkit";
import { TEscalationNumber, TToken, TUser, TUserSlice } from "./types";
import platform from "platform";

const initialState: TUserSlice = {
  token: undefined,
  user: undefined,
  browserName: platform.name ?? "",
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserAction: (
      state,
      action: {
        payload: TUser | undefined;
      }
    ) => ({
      ...state,
      user: action.payload,
    }),
    setEscalationNumberAction: (
      state,
      action: {
        payload: TEscalationNumber[];
      }
    ) => ({
      ...state,
      user: {
        ...state.user!,
        escalation_phonenumbers: action.payload,
      },
    }),
    setTokenAction: (
      state,
      action: {
        payload: TToken | undefined;
      }
    ) => ({
      ...state,
      token: action.payload,
    }),
  },
});

export const { setUserAction, setTokenAction, setEscalationNumberAction } =
  userSlice.actions;

export default userSlice.reducer;
