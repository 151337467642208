import { TToken, TUser } from "state/user/types";
import { KEYS } from "types";

export const getUserInfoFromLocalStorage = () => {
  const result = localStorage.getItem(KEYS.USER);
  if (result) {
    const user: TUser = JSON.parse(result);
    return user;
  }
  return null;
};

export const getTokenFromLocalStorage = () => {
  const result = localStorage.getItem(KEYS.TOKEN);
  if (result) {
    const token: TToken = JSON.parse(result);
    return token;
  }
  return null;
};

export const saveUserInfoInLocalStorage = (user: TUser) => {
  const data = JSON.stringify(user);
  localStorage.setItem(KEYS.USER, data);
};
export const saveTokenInLocalStorage = (token: TToken) => {
  const data = JSON.stringify(token);
  localStorage.setItem(KEYS.TOKEN, data);
};

export const deleteUserInfoFromLocalStorage = () => {
  localStorage.removeItem(KEYS.USER);
  localStorage.removeItem(KEYS.TOKEN);
};
