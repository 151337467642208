import React, { useCallback, useEffect, useState } from "react";
import Button from "components/Button";
import OTPInput from "react-otp-input";
import PhoneInput from "components/Input/PhoneNumber";
import { TVerifyOtpPayload } from "types/user-management";
import { useFormik } from "formik";
import { verifyOTPSchema } from "schema/user-management";
import { useLoading } from "state/loading/hooks";
import { LOADINGSTATE } from "state/loading/types";
import Error from "components/Error";

type TProps = {
  submitHandler: (payload: TVerifyOtpPayload) => Promise<void>;
  phone_number: string | undefined;
  resendOTP: (phone_number: string) => Promise<void>;
};

function Form({ submitHandler, phone_number, resendOTP }: TProps) {
  const [otp, setOtp] = useState("");
  const { isLoading } = useLoading();
  const {
    handleSubmit,
    values,
    touched,
    errors,
    setFieldValue,
    setFieldError,
  } = useFormik({
    initialValues: {
      phone_number: phone_number ?? "",
      otp,
    },
    validationSchema: verifyOTPSchema,
    onSubmit: async (values, { setSubmitting }) => {
      await submitHandler(values);
      setSubmitting(false);
    },
  });

  const resendCode = useCallback(async () => {
    if (!values.phone_number.length) {
      setFieldError("phone_number", "Phone number is required");
      return;
    }
    await resendOTP(values.phone_number);
  }, [values, setFieldError, resendOTP]);

  useEffect(() => {
    if (!otp.length) return;
    setFieldValue("otp", otp);
  }, [otp, setFieldValue]);

  return (
    <form
      onSubmit={handleSubmit}
      noValidate
      className="flex items-center flex-col gap-y-5 mobile:gap-y-3  max-w-[370px]"
    >
      <OTPInput
        value={values.otp}
        onChange={setOtp}
        numInputs={4}
        inputType="number"
        renderSeparator={<span className="ml-3"></span>}
        renderInput={(props) => <input {...props} />}
        inputStyle={
          "!w-[82.417px] h-[103.94px] bg-white focus:outline-none font-semibold text-[48px] text-center shadow-input"
        }
      />
      {touched.otp && errors.otp && (
        <Error error={errors.otp} className="self-start -mt-3" />
      )}
      <PhoneInput
        setValue={setFieldValue}
        defaultValue={values.phone_number}
        name="phone_number"
        className="w-full"
        error={touched.phone_number && errors.phone_number}
      />

      <Button
        title="Verify validation code"
        className="w-[237px] h-[44px] bg-button"
        type="submit"
        isLoading={isLoading[LOADINGSTATE.VERIFYOTP]}
      />
      <p
        onClick={resendCode}
        className="text-center text-main typography text-sm cursor-pointer"
      >
        Resend validation code
      </p>
    </form>
  );
}

export default Form;
