import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

export enum MODALS {
  UPDATENAME = "update_name",
  UPDATEPASS = "update_password",
  UPDATENUMBER = "update_escalation_number",
  UPDATEALARM = "update_alarm",
  SETALARM = "set_alarm",
}
type TMODALSSTATE = {
  [key in MODALS]: boolean;
};
type TModalContext = {
  isModalOpen: TMODALSSTATE;
  handleClose: (key: MODALS) => void;
  handleOpen: (key: MODALS) => void;
};

type TModalProvider = {
  children: React.ReactNode;
};

const ModalContext = createContext<TModalContext>({
  isModalOpen: {
    [MODALS.UPDATENAME]: false,
    [MODALS.UPDATEPASS]: false,
    [MODALS.UPDATENUMBER]: false,
    [MODALS.UPDATEALARM]: false,
    [MODALS.SETALARM]: false,
  },
  handleClose: (key: MODALS) => {},
  handleOpen: (key: MODALS) => {},
});

const ModalProvider = ({ children }: TModalProvider) => {
  const [isModalOpen, setIsModalOpen] = useState<TMODALSSTATE>({
    [MODALS.UPDATENAME]: false,
    [MODALS.UPDATEPASS]: false,
    [MODALS.UPDATENUMBER]: false,
    [MODALS.UPDATEALARM]: false,
    [MODALS.SETALARM]: false,
  });

  const handleClose = useCallback((key: MODALS) => {
    setIsModalOpen((prev) => {
      return {
        ...prev,
        [key]: false,
      };
    });
  }, []);
  const handleOpen = useCallback(
    (key: MODALS) => {
      setIsModalOpen((prev) => {
        return {
          ...prev,
          [key]: !isModalOpen[key],
        };
      });
    },
    [isModalOpen]
  );

  const value = useMemo(() => {
    return {
      isModalOpen,
      handleClose,
      handleOpen,
    };
  }, [isModalOpen, handleClose, handleOpen]);

  return (
    <ModalContext.Provider value={value}>{children}</ModalContext.Provider>
  );
};

export default ModalProvider;

export const useModal = () => {
  return useContext(ModalContext);
};
