import React from "react";
import Button from "components/Button";
import PhoneInput from "components/Input/PhoneNumber";
import { useFormik } from "formik";
import { forgotPasswordSchema } from "schema/auth";
import { FORGOTPASSWORDINITIALVALUES } from "constants/form";

type TProps = {
  submitHandler: (phone_number: string) => Promise<void>;
  isLoading: boolean;
};
function Form({ submitHandler, isLoading }: TProps) {
  const { handleSubmit, touched, errors, setFieldValue } = useFormik({
    initialValues: FORGOTPASSWORDINITIALVALUES,
    validationSchema: forgotPasswordSchema,

    onSubmit: async (values, { setSubmitting }) => {
      await submitHandler(values.phone_number);
      setSubmitting(false);
    },
  });
  return (
    <form
      noValidate
      onSubmit={handleSubmit}
      className="flex flex-col gap-y-4 mobile:gap-y-2 items-center justify-center w-full max-w-[370px]"
    >
      <PhoneInput
        setValue={setFieldValue}
        name="phone_number"
        className="w-full"
        error={touched.phone_number && errors.phone_number}
      />
      <Button
        title="Submit"
        className="w-[237px] h-[44px] bg-button my-2 "
        type="submit"
        isLoading={isLoading}
      />
    </form>
  );
}

export default Form;
