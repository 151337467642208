import { createSlice } from "@reduxjs/toolkit";
import { TLoadingSlice, LOADINGSTATE } from "./types";

const initialState: TLoadingSlice = {
  isLoading: {
    [LOADINGSTATE.AUTH]: false,
    [LOADINGSTATE.USERMANAGEMENT]: false,
    [LOADINGSTATE.ESCALATIONNUMBER]: false,
    [LOADINGSTATE.ALARMS]: false,
    [LOADINGSTATE.IMAGES]: false,
    [LOADINGSTATE.VERIFYOTP]: false,
    [LOADINGSTATE.RESENDOTP]: false,
  },
};

const loadingSlice = createSlice({
  name: "loading",
  initialState,
  reducers: {
    setIsLoadingStateAction: (
      state,
      action: {
        payload: { [key in LOADINGSTATE]?: boolean };
      }
    ) => ({
      isLoading: {
        ...state.isLoading,
        ...action.payload,
      },
    }),
  },
});

export const { setIsLoadingStateAction } = loadingSlice.actions;

export default loadingSlice.reducer;
