import React from "react";
import CardLayout from "components/CardLayout";
import { edit_icon } from "assets";
import { DAYPERIOD } from "types/alarm";

type TProps = {
  day: string;
  time: string;
  dayPeriod: DAYPERIOD;
  onClick: () => void;
};

function AlarmScheduleCard({ day, time, dayPeriod, onClick }: TProps) {
  return (
    <CardLayout className="py-6 px-[18px]">
      <div className="flex justify-between items-center">
        <div className="flex flex-col gap-y-5">
          <h2 className="text-main text-3xl mobile:text-2xl typography">
            {day}
          </h2>
          {/* REMOVING TOGGLE BUTTON TEMPORARILY */}
          {/* <ToggleButton dayPeriod={dayPeriod} /> */}
        </div>
        <div className="flex item-center gap-x-6">
          <h2 className="text-main text-3xl mobile:text-2xl typography">
            {time}
          </h2>
          <button onClick={onClick}>
            <img src={edit_icon} alt="edit_icon" />
          </button>
        </div>
      </div>
    </CardLayout>
  );
}

export default AlarmScheduleCard;
