import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { api } from "config/api";
import { TLoginPayload, TResetPasswordPayload } from "types/auth";
import { toast } from "react-toastify";
import { useLoading } from "state/loading/hooks";
import { LOADINGSTATE } from "state/loading/types";
import { useUser } from "state/user/hooks";
import { PATHS } from "constants/paths";
import { useAuthState } from "state/auth/hooks";
import { handleServerError } from "utils/error";

export const useAuth = () => {
  const { isLoading, setIsLoading } = useLoading();
  const { setToken, setUser } = useUser();
  const { setPhoneNumber } = useAuthState();
  const navigate = useNavigate();
  const loginUser = useCallback(
    async (payload: TLoginPayload) => {
      try {
        setIsLoading({ [LOADINGSTATE.AUTH]: true });
        const { data } = await api.post("users/me/token/", payload);
        if (!data) return;
        setToken({ access_token: data.access, refresh_token: data.refresh });
        navigate(PATHS.DASHBOARD);
        toast.success("Welcome to GMAOOB");
      } catch (e) {
        handleServerError(e);
      } finally {
        setIsLoading({ [LOADINGSTATE.AUTH]: false });
      }
    },
    [setIsLoading, setToken, navigate]
  );
  const logoutUser = useCallback(() => {
    try {
      setToken(undefined);
      setUser(undefined);
      toast.success("Logout Successful.");
      navigate(PATHS.LOGIN);
    } catch (e) {
      handleServerError(e);
    }
  }, [setToken, setUser, navigate]);

  const forgotPassword = useCallback(
    async (phone_number: string) => {
      try {
        setIsLoading({ [LOADINGSTATE.AUTH]: true });
        const { data } = await api.post("users/me/forgot_password/", {
          phone_number,
        });
        if (!data) return;
        if (!data.message) return;
        setPhoneNumber(phone_number);
        navigate(PATHS.RESETPASSWORD);
        toast.success(data.message);
      } catch (e) {
        handleServerError(e);
      } finally {
        setIsLoading({ [LOADINGSTATE.AUTH]: false });
      }
    },
    [setIsLoading, navigate, setPhoneNumber]
  );

  const resetPassword = useCallback(
    async (payload: TResetPasswordPayload) => {
      try {
        setIsLoading({ [LOADINGSTATE.AUTH]: true });
        const { data } = await api.post("users/me/reset_password/", payload);
        if (!data) return;
        if (!data.message) return;
        toast.success(data.message);
        navigate(PATHS.LOGIN);
      } catch (e) {
        handleServerError(e);
      } finally {
        setIsLoading({ [LOADINGSTATE.AUTH]: false });
      }
    },
    [setIsLoading, navigate]
  );

  return {
    loginUser,
    forgotPassword,
    resetPassword,
    logoutUser,
    setIsLoading,
    isLoading,
  };
};
