import { useSelector } from "react-redux";
import { useCallback } from "react";
import { RootState, useAppDispatch } from "state/store";
import { setAlarmsAction, setImagesAction, setSelectedAlarmAction } from ".";
import { TAlarm, TDashboardSlice } from "./types";

export const useDashbaord = () => {
  const { alarms, images, selectedAlarm } = useSelector<
    RootState,
    TDashboardSlice
  >((state) => state.dashboard);
  const dispatch = useAppDispatch();

  const setAlarms = useCallback(
    (payload: TAlarm[]) => {
      dispatch(setAlarmsAction(payload));
    },
    [dispatch]
  );
  const setImages = useCallback(
    (payload: string[]) => {
      dispatch(setImagesAction(payload));
    },
    [dispatch]
  );
  const setSelectedAlarm = useCallback(
    (payload: TAlarm | undefined) => {
      dispatch(setSelectedAlarmAction(payload));
    },
    [dispatch]
  );

  return {
    alarms,
    setAlarms,
    images,
    setImages,
    selectedAlarm,
    setSelectedAlarm,
  };
};
