import React from "react";
import Button from "components/Button";
import Input from "components/Input";
import PhoneInput from "components/Input/PhoneNumber";
import { useFormik } from "formik";
import { resetPasswordSchema } from "schema/auth";
import { TResetPasswordPayload } from "types/auth";
import { RESETPASSWORDINITIALVALUES } from "constants/form";

type TProps = {
  submitHandler: (payload: TResetPasswordPayload) => Promise<void>;
  phone_number: string | undefined;
  isLoading: boolean;
};
function Form({ submitHandler, phone_number, isLoading }: TProps) {
  const { handleSubmit, handleChange, values, touched, errors, setFieldValue } =
    useFormik({
      initialValues: phone_number
        ? {
            ...RESETPASSWORDINITIALVALUES,
            phone_number,
          }
        : RESETPASSWORDINITIALVALUES,
      validationSchema: resetPasswordSchema,

      onSubmit: async (values, { setSubmitting }) => {
        await submitHandler(values);
        setSubmitting(false);
      },
    });
  return (
    <form
      noValidate
      onSubmit={handleSubmit}
      className="flex flex-col gap-y-4 mobile:gap-y-2 items-center justify-center w-full max-w-[370px]"
    >
      <PhoneInput
        setValue={setFieldValue}
        defaultValue={values.phone_number}
        name="phone_number"
        className="w-full"
        error={touched.phone_number && errors.phone_number}
      />
      <Input
        placeholder="Enter temporary password"
        className="w-full"
        error={touched.temp_password && errors.temp_password}
        onChange={handleChange}
        value={values.temp_password}
        name="temp_password"
      />
      <Input
        placeholder="Enter new password"
        className="w-full"
        error={touched.new_password && errors.new_password}
        onChange={handleChange}
        value={values.new_password}
        name="new_password"
      />
      <Button
        title="Submit"
        className="w-[237px] bg-button my-2"
        type="submit"
        isLoading={isLoading}
      />
    </form>
  );
}

export default Form;
