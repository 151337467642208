import React, { Fragment } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { useUser } from "state/user/hooks";
import { PATHS } from "constants/paths";
import Introduction from "pages/Auth/Introduction";
import Registration from "pages/Auth/Registration";
import VerifyOtp from "pages/Auth/Registration/VerifyOtp";
import Login from "pages/Auth/Login";
import ForgotPassword from "pages/Auth/ForgotPassword";
import ResetPassword from "pages/Auth/ResetPassword";
import Dashboard from "pages/App/Dashboard";
import Setting from "pages/App/Settings";
import SubmitSelfie from "pages/App/SubmitSelfie";
import TermsAndCondition from "pages/Auth/TermsAndCondition";

const Router = () => {
  const { token } = useUser();
  return (
    <Routes>
      {token ? (
        <Fragment>
          <Route path={PATHS.DASHBOARD} element={<Dashboard />} />
          <Route path={PATHS.SETTINGS} element={<Setting />} />
          <Route path={PATHS.SUBMIT} element={<SubmitSelfie />} />
          {/* <Route path={PATHS.ALARM} element={<Alarm />} /> */}
          <Route path="*" element={<Navigate to={PATHS.DASHBOARD} />} />
        </Fragment>
      ) : (
        <Fragment>
          <Route path={PATHS.HOME} element={<Introduction />} />
          <Route path={PATHS.LOGIN} element={<Login />} />
          <Route path={PATHS.REGISTRATION} element={<Registration />} />
          <Route path={PATHS.FORGOTPASSWORD} element={<ForgotPassword />} />
          <Route path={PATHS.RESETPASSWORD} element={<ResetPassword />} />
          <Route path={PATHS.VERIFYOTP} element={<VerifyOtp />} />
          <Route path="*" element={<Navigate to={PATHS.LOGIN} />} />
        </Fragment>
      )}
      <Route path={PATHS.TERMS} element={<TermsAndCondition />} />
    </Routes>
  );
};

export default Router;
