/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useEffect, useMemo } from "react";
import { getTokenFromLocalStorage } from "utils/cache";
import { useUser } from "state/user/hooks";
import { useUserManagement } from "hooks/user-management/useUserManagement";
import { useAlarms } from "hooks/alarms/useAlarms";

type TAppContext = {};

type TAppProvider = {
  children: React.ReactNode;
};

const AppContext = createContext<TAppContext>({});

const AppProvider = ({ children }: TAppProvider) => {
  const { token, setToken } = useUser();
  const { getUserInfo } = useUserManagement();
  const { getUserAlarms } = useAlarms();

  const value = useMemo(() => {
    return {};
  }, []);

  useEffect(() => {
    const retrievedTokens = getTokenFromLocalStorage();
    if (!retrievedTokens) return;
    if (!token) setToken(retrievedTokens);
    getUserInfo();
    getUserAlarms();
  }, [token]);

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export default AppProvider;

export const useApp = () => {
  return useContext(AppContext);
};
