import React from "react";
import { arrow_icon } from "assets";
import { useNavigate } from "react-router-dom";
import { PATHS } from "constants/paths";
import { useSwiper } from "swiper/react";
import platform from "platform";
type TProps = {
  isLastSlide: boolean;
};
function CarouselActions({ isLastSlide }: TProps) {
  const swiper = useSwiper();
  const navigate = useNavigate();

  const handleSlideChange = () => {
    swiper.slideNext();
  };
  const isDeviceIPhone = platform.product === "iPhone";

  return (
    <div
      className={`flex justify-between w-[calc(100%_-_50px)] left-7 absolute bottom-7 mobile:bottom-14 z-10 ${
        isDeviceIPhone && "actions"
      }`}
    >
      {isLastSlide ? (
        <button
          onClick={() => navigate(PATHS.LOGIN)}
          className="typography w-[180px] mx-auto py-2 px-3 rounded-[3px] text-base text-link border-[#2A3D6F] border-solid border-2
          transition-all duration-200 hover:bg-button hover:text-white
          "
        >
          Get Started
        </button>
      ) : (
        <>
          <button
            className="cursor-pointer"
            onClick={() => {
              navigate(PATHS.LOGIN);
            }}
          >
            Skip
          </button>
          <button onClick={handleSlideChange}>
            <img
              src={arrow_icon}
              className="w-[24px] object-contain rotate-180"
              alt="arow-icon"
            />
          </button>
        </>
      )}
    </div>
  );
}

export default CarouselActions;
