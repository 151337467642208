import axios, { InternalAxiosRequestConfig } from "axios";
import {
  deleteUserInfoFromLocalStorage,
  getTokenFromLocalStorage,
  saveTokenInLocalStorage,
} from "./cache";
import { PATHS } from "constants/paths";
import { toast } from "react-toastify";

const baseUrl = process.env.REACT_APP_PUBLIC_BASE_URL;

export const _redirectToLogin = () => {
  deleteUserInfoFromLocalStorage();
  window.location.href = PATHS.LOGIN;
};
export const verifyAccessToken = async (
  config: InternalAxiosRequestConfig<any>
) => {
  try {
    const token = getTokenFromLocalStorage();
    if (!token) return config;
    await axios.post(`${baseUrl}users/token/verify/`, {
      token: token.access_token,
    });
    //token is valid add it in config
    config.headers.Authorization = "Bearer " + token.access_token;
    return config;
  } catch (e) {
    //token verification failed. 401 or some other error from server
    return Promise.reject(e);
  }
};

export const refreshAccessToken = async () => {
  try {
    const token = getTokenFromLocalStorage();
    if (!token) return;
    const { data } = await axios.post(`${baseUrl}users/me/token/refresh/`, {
      token: token.refresh_token,
    });
    saveTokenInLocalStorage({
      access_token: data.access,
      refresh_token: data.refresh,
    });
    return data;
  } catch (e) {
    toast.error("Token refresh failed. Redirecting to login");
    _redirectToLogin();
    //token refresh failed. 401 or some other error from server
    return Promise.reject(e);
  }
};
