import { createSlice } from "@reduxjs/toolkit";
import { TAlarm, TDashboardSlice } from "./types";

const initialState: TDashboardSlice = {
  alarms: [],
  images: [],
  selectedAlarm: undefined,
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setAlarmsAction: (
      state,
      action: {
        payload: TAlarm[];
      }
    ) => ({
      ...state,
      alarms: action.payload,
    }),
    setImagesAction: (
      state,
      action: {
        payload: string[];
      }
    ) => ({
      ...state,
      images: action.payload,
    }),
    setSelectedAlarmAction: (
      state,
      action: {
        payload: TAlarm | undefined;
      }
    ) => ({
      ...state,
      selectedAlarm: action.payload,
    }),
  },
});

export const { setAlarmsAction, setImagesAction, setSelectedAlarmAction } =
  dashboardSlice.actions;

export default dashboardSlice.reducer;
