import React from "react";
import Name from "./components/Form/UserInfo/Name";
import Password from "./components/Form/UserInfo/Password";
import EscalationNumber from "./components/Form/EscalationNumber";
import PhoneInput from "components/Input/PhoneNumber";
import Button from "components/Button";
import { useUser } from "state/user/hooks";
import { useAuth } from "hooks/auth/useAuth";
import { useUserManagement } from "hooks/user-management/useUserManagement";
import AppLayout from "components/Layouts/App";
import { useLoading } from "state/loading/hooks";
import { LOADINGSTATE } from "state/loading/types";

function Setting() {
  const { user } = useUser();
  const { logoutUser } = useAuth();
  const { isLoading } = useLoading();
  const { updateUserInfo } = useUserManagement();
  return (
    <AppLayout>
      <div className="flex flex-col gap-y-6 mobile:gap-y-4 items-center max-w-[380px] mobile:max-w-full w-full mx-auto mobile:h-[calc(100vh_-_70px)] mobile:overflow-y-auto">
        <PhoneInput
          defaultValue={user?.phone_number}
          readOnly
          label="Phone"
          className="w-full"
        />
        <Name
          value={user?.name ?? ""}
          updateUserInfo={updateUserInfo}
          isLoading={isLoading[LOADINGSTATE.USERMANAGEMENT]}
        />
        <EscalationNumber
          value={user?.escalation_phonenumbers ?? []}
          isLoading={isLoading[LOADINGSTATE.ESCALATIONNUMBER]}
        />
        <Password
          value=""
          updateUserInfo={updateUserInfo}
          isLoading={isLoading[LOADINGSTATE.USERMANAGEMENT]}
        />
        <Button
          title="Logout"
          onClick={logoutUser}
          className="w-[237px] h-[44px] bg-[#F8832E] my-14 mobile:my-10"
        />
      </div>
    </AppLayout>
  );
}

export default Setting;
