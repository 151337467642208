import { Fragment } from "react";
import CardLayout from "components/CardLayout";
import { clock_icon } from "assets";
import SetAlarmOptionsPanel from "./OptionsPanel";
import { TCreateAlarmPayload } from "types/alarm";
import { MODALS, useModal } from "context/Modal";
import Modal from "components/Modal";
import { useLoading } from "state/loading/hooks";
import { LOADINGSTATE } from "state/loading/types";

type TProps = {
  createAlarm: (payload: TCreateAlarmPayload) => Promise<void>;
  scheduledDays: number[];
};
function SetAlarms({ createAlarm, scheduledDays }: TProps) {
  const { handleOpen } = useModal();
  const { isLoading } = useLoading();
  return (
    <Fragment>
      <CardLayout className="mt-5">
        <div
          className="flex items-center px-8 py-6 gap-x-5 cursor-pointer"
          onClick={() => handleOpen(MODALS.SETALARM)}
        >
          <img
            src={clock_icon}
            alt="clock_icon"
            className="w-[50px] object-contain"
          />
          <h1 className="text-3xl mobile:text-2xl text-main typography">
            Set Alarm
          </h1>
        </div>
      </CardLayout>
      <Modal modal={MODALS.SETALARM} className="max-w-[650px]">
        <SetAlarmOptionsPanel
          submitHandler={createAlarm}
          isLoading={isLoading[LOADINGSTATE.ALARMS]}
          scheduledDays={scheduledDays}
        />
      </Modal>
    </Fragment>
  );
}

export default SetAlarms;
