import { useImageService } from "hooks/image_service/useImageService";
import Form from "./components/Form";
import AppLayout from "components/Layouts/App";
import { useLoading } from "state/loading/hooks";

function UploadSelfie() {
  const { uploadSelfie } = useImageService();
  const { isLoading } = useLoading();
  return (
    <AppLayout>
      <Form isLoading={isLoading.images} submitHandler={uploadSelfie} />
    </AppLayout>
  );
}

export default UploadSelfie;
