import React from "react";
import { Link, useLocation } from "react-router-dom";
import { setting_icon, arrow_icon } from "assets";
import { PATHS } from "constants/paths";

type TProps = {
  title: string;
  setting: boolean;
  className?: string;
};
const Header = ({ title, setting, className = "" }: TProps) => {
  const { pathname } = useLocation();
  return (
    <div className={`flex justify-between items-center h-[30px] ${className}`}>
      <div className="flex gap-x-3 items-center mt-2">
        {pathname !== PATHS.DASHBOARD && (
          <Link to={PATHS.DASHBOARD}>
            <img
              src={arrow_icon}
              alt="arrow icon"
              className="w-[34px] h-[35px]"
            />
          </Link>
        )}
        <h1 className="text-black typography text-2xl">{title}</h1>
      </div>
      <Link to={PATHS.SETTINGS} className={`${!setting && "hidden"}`}>
        <img src={setting_icon} alt="setting icon" />
      </Link>
    </div>
  );
};

export default Header;
