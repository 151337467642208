import React from "react";
import { MODALS, useModal } from "context/Modal";
import ReactPortal from "components/Portal";

type TProps = {
  modal: MODALS;
  children: React.ReactNode;
  className?: string;
};

const Modal = ({ children, className, modal }: TProps) => {
  const { handleClose, isModalOpen } = useModal();
  return (
    <ReactPortal>
      <div
        className={`fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 transition-all ease-in duration-150 ${
          isModalOpen[modal]
            ? "opacity-1 visible translate-y-0"
            : "opacity-0 invisible translate-y-[-15px]"
        }`}
      >
        <div className={["bg-white w-full", className].join(" ")}>
          <header className="py-4 relative w-full">
            <button
              className="absolute top-1/2 translate-y-[-50%] right-[10px] cursor-pointer font-sans text-lg font-semibold"
              onClick={() => handleClose(modal)}
            >
              x
            </button>
          </header>
          <div className="px-5 py-4">{children}</div>
        </div>
      </div>
    </ReactPortal>
  );
};

export default Modal;
