import { object, string } from "yup";
import password_schema from "schema/password_schema";
import phone_number_schema from "schema/phone_number_schema";

export const loginSchema = object({
  phone_number: phone_number_schema,
  password: string().required("Password is required"),
});
export const forgotPasswordSchema = object({
  phone_number: phone_number_schema,
});

export const resetPasswordSchema = object({
  phone_number: phone_number_schema,
  temp_password: string().required("Temporary password cannot be blank"),
  new_password: password_schema,
});
