import { useCallback } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../store";
import { setPhoneNumberAction } from ".";
import { TAuthSlice } from "./types";

export const useAuthState = () => {
  const { phone_number } = useSelector<RootState, TAuthSlice>(
    (state) => state.auth
  );
  const dispatch = useAppDispatch();

  const setPhoneNumber = useCallback(
    (payload: string | undefined) => {
      dispatch(setPhoneNumberAction(payload));
    },
    [dispatch]
  );

  return {
    setPhoneNumber,
    phone_number,
  };
};
