import { object, string } from "yup";
import password_schema from "schema/password_schema";
import phone_number_schema from "schema/phone_number_schema";

export const registrationSchema = object({
  name: string().required("Name is required"),
  phone_number: phone_number_schema,
  password: password_schema,
});

export const verifyOTPSchema = object({
  phone_number: phone_number_schema,
  otp: string()
    .required("Validation code cannot be blank")
    .matches(/^\d+$/, "Validation code must contain only digits")
    .max(4, "Validation code must be 4 digits"),
});

export const updateUserEscalationNumberSchema = object({
  phone_number: phone_number_schema,
});

export const updateUserNameSchema = object({
  name: string().required("Name cannot be blank"),
});
export const updateUserPasswordSchema = object({
  password: password_schema,
});
