import React, { useEffect, useState } from "react";
import { camera_lined_icon, default_image } from "assets";
import Button from "components/Button";
import Error from "components/Error";

type TProps = {
  isLoading: boolean;
  submitHandler: (file: File) => Promise<void>;
};
function Form({ isLoading, submitHandler }: TProps) {
  //STATES
  const [values, setValues] = useState<{
    image: File | null;
    imageUrl: string;
  }>({
    imageUrl: "",
    image: null,
  });
  const [error, setError] = useState("");
  //HANDLERS
  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setValues((prev) => ({
        ...prev,
        image: file,
      }));
      const reader = new FileReader();
      reader.onload = (e) => {
        setValues((prev) => ({
          ...prev,
          //@ts-ignore
          imageUrl: e.currentTarget?.result,
        }));
      };
      reader.readAsDataURL(file);
    }
  };
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!values.image) {
      setError("Please choose an image before submitting.");
      return;
    }
    await submitHandler(values.image);
  };
  useEffect(() => {
    if (!values.image) return;
    setError("");
  }, [values]);

  return (
    <form
      className="w-full max-w-[360px] flex flex-col gap-y-7 items-center mx-auto"
      onSubmit={handleSubmit}
      noValidate
    >
      <div className="w-full h-[470px] rounded-md relative bg-[#c4c4c4] mobile:h-[390px]">
        <input
          onChange={handleImageUpload}
          name="image"
          id="image"
          type="file"
          accept=".jpg, .jpeg, .png"
          className="w-full h-full opacity-0 absolute z-1 cursor-pointer"
        />
        <img
          src={values.imageUrl.length ? values.imageUrl : default_image}
          alt="selfie"
          className={`w-full h-full ${values.imageUrl && "object-contain"}`}
        />
        <label htmlFor="image" className="cursor-pointer">
          <img
            src={camera_lined_icon}
            alt="camera_icon"
            className="w-[50px] h-[50px] absolute bottom-6 left-1/2 translate-x-[-50%]"
          />
        </label>
      </div>
      {error.length > 0 && <Error error={error} className="self-start" />}
      <Button
        title="Submit"
        className="w-[237px] bg-button"
        type="submit"
        isLoading={isLoading}
      />
    </form>
  );
}

export default Form;
