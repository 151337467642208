import { useSelector } from "react-redux";
import { useCallback } from "react";
import { RootState, useAppDispatch } from "state/store";
import { setIsLoadingStateAction } from ".";
import { LOADINGSTATE, TLoadingSlice } from "./types";

export const useLoading = () => {
  const { isLoading } = useSelector<RootState, TLoadingSlice>(
    (state) => state.loading
  );
  const dispatch = useAppDispatch();

  const setIsLoading = useCallback(
    (payload: { [key in LOADINGSTATE]?: boolean }) => {
      dispatch(setIsLoadingStateAction(payload));
    },
    [dispatch]
  );

  return {
    isLoading,
    setIsLoading,
  };
};
