import React, { useMemo } from "react";
import { useLocation } from "react-router-dom";
import Header from "components/Header";
import { PATHS } from "constants/paths";

type TProps = {
  children: React.ReactNode;
};

function AppLayout({ children }: TProps) {
  const { pathname } = useLocation();
  const getTitle = useMemo(() => {
    if (pathname === PATHS.SETTINGS) return "Settings";
    if (pathname === PATHS.SUBMIT) return "Submit";
    if (pathname === PATHS.ALARM) return "Alarm";

    return "Dashboard";
  }, [pathname]);
  return (
    <div className="bg-main w-full flex flex-col gap-y-7 p-[25px] mobile:px-4 overflow-y-hidden">
      <Header title={getTitle} setting={pathname === PATHS.DASHBOARD} />
      {children}
    </div>
  );
}

export default AppLayout;
