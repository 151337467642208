import {
  onboarding_image_01,
  onboarding_image_02,
  onboarding_image_03,
} from "assets";
export const INTRODATA = [
  {
    img: onboarding_image_01,
    text: "Have you been gaming just a bit too much to wake up to that alarm?",
  },
  {
    img: onboarding_image_02,
    text: "Maybe you spend too much time on that damn phone and want to be a normal human being with a functional sleep schedule?",
  },
  {
    img: onboarding_image_03,
    text: "Get started with GMAOOB today and proudly say “Got my ass out of bed!”",
  },
];
