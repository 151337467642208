import { number, object, string } from "yup";

export const setAlarmSchema = object({
  hourFirstDigit: string().matches(/^[0-1]$/, "Invalid hour"),
  hourSecondDigit: string()
    .when("hourFirstDigit", {
      is: "1",
      then: (schema) => schema.matches(/^[0-2]$/, "Invalid hour"),
    })
    .matches(/^[0-9]$/, "Invalid hour")
    .required("Required"),
  minuteFirstDigit: string()
    .matches(/^[0-5]$/, "Invalid minute")
    .required("Required"),
  minuteSecondDigit: string()
    .matches(/^[0-9]$/, "Invalid minute")
    .required("Required"),
  dayPeriod: string()
    .required("Required")
    .oneOf(["AM", "PM"], "Invalid day period"),
  day: number()
    .min(0, "Invalid day")
    .max(6, "Invalid day")
    .required("Required"),
});

export const updateAlarmSchema = object({
  hourFirstDigit: string().matches(/^[0-1]$/, "Invalid hour"),
  hourSecondDigit: string()
    .when("hourFirstDigit", {
      is: "1",
      then: (schema) => schema.matches(/^[0-2]$/, "Invalid hour"),
    })
    .matches(/^[0-9]$/, "Invalid hour")
    .required("Required"),
  minuteFirstDigit: string()
    .matches(/^[0-5]$/, "Invalid minute")
    .required("Required"),
  minuteSecondDigit: string()
    .matches(/^[0-9]$/, "Invalid minute")
    .required("Required"),
  dayPeriod: string()
    .required("Required")
    .oneOf(["AM", "PM"], "Invalid day period"),
});
