import { useCallback } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../store";
import { setUserAction, setTokenAction, setEscalationNumberAction } from ".";
import { TEscalationNumber, TToken, TUser, TUserSlice } from "./types";
import {
  deleteUserInfoFromLocalStorage,
  saveUserInfoInLocalStorage,
  saveTokenInLocalStorage,
} from "utils/cache";

export const useUser = () => {
  const { token, user, browserName } = useSelector<RootState, TUserSlice>(
    (state) => state.user
  );
  const dispatch = useAppDispatch();

  const setUser = useCallback(
    (payload: TUser | undefined) => {
      dispatch(setUserAction(payload));
      if (payload) saveUserInfoInLocalStorage(payload);
      else deleteUserInfoFromLocalStorage();
    },
    [dispatch]
  );
  const setEscalationNumber = useCallback(
    (payload: TEscalationNumber[]) => {
      dispatch(setEscalationNumberAction(payload));
    },
    [dispatch]
  );
  const setToken = useCallback(
    (payload: TToken | undefined) => {
      dispatch(setTokenAction(payload));
      if (payload) saveTokenInLocalStorage(payload);
      else deleteUserInfoFromLocalStorage();
    },
    [dispatch]
  );

  return {
    browserName,
    token,
    user,
    setUser,
    setToken,
    setEscalationNumber,
  };
};
