import React, { Fragment } from "react";
import Input from "pages/App/Settings/components/Form/Input";
import Modal from "components/Modal";
import Button from "components/Button";
import { useFormik } from "formik";
import { updateUserNameSchema } from "schema/user-management";
import { MODALS, useModal } from "context/Modal";
import { TUpdateUserPayload } from "types/user-management";

type TProps = {
  value: string;
  updateUserInfo: (payload: TUpdateUserPayload) => Promise<void>;
  isLoading: boolean;
};
function Name({ value, updateUserInfo, isLoading }: TProps) {
  const { handleOpen, handleClose } = useModal();
  const { handleSubmit, handleChange, values, touched, errors } = useFormik({
    initialValues: {
      name: value,
    },
    validationSchema: updateUserNameSchema,
    onSubmit: async (values, { setSubmitting }) => {
      await updateUserInfo(values);
      setSubmitting(false);
      handleClose(MODALS.UPDATENAME);
    },
  });
  return (
    <Fragment>
      <Input
        label="Name"
        placeholder="Enter your name"
        value={value}
        name="name"
        className="w-full"
        onFocus={() => handleOpen(MODALS.UPDATENAME)}
        readOnly
        isEditable
      />
      <Modal className="max-w-[400px]" modal={MODALS.UPDATENAME}>
        <form
          noValidate
          onSubmit={handleSubmit}
          className="max-w-[380px] w-full flex flex-col items-center gap-y-6"
        >
          <Input
            label="Name"
            placeholder="Enter your name"
            error={touched.name && errors.name}
            onChange={handleChange}
            value={values.name}
            name="name"
            className="w-full"
          />
          <Button
            title="Save"
            className="w-[237px] h-[44px] bg-button"
            type="submit"
            isLoading={isLoading}
          />
        </form>
      </Modal>
    </Fragment>
  );
}

export default Name;
