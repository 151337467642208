import React from "react";
import CardLayout from "components/CardLayout";
import { camera_icon } from "assets";
import { PATHS } from "constants/paths";
import { Link } from "react-router-dom";

function SelfiePromptCard() {
  return (
    <Link to={PATHS.SUBMIT}>
      <CardLayout>
        <div className="flex items-center px-8 py-6 gap-x-5">
          <img
            src={camera_icon}
            alt="camera_icon"
            className="w-[50px] object-contain"
          />
          <h1 className="text-3xl mobile:text-2xl text-main typography">
            Submit Selfie{" "}
          </h1>
        </div>
      </CardLayout>
    </Link>
  );
}

export default SelfiePromptCard;
