import axios from "axios";
import { refreshAccessToken, verifyAccessToken } from "utils/middleware";
/**
 * axios instance
 */
export const api = axios.create({
  baseURL: process.env.REACT_APP_PUBLIC_BASE_URL,
});
// request header
api.interceptors.request.use(
  async (config) => {
    await verifyAccessToken(config);
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// response parse
api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (
      error.config.url !==
      `${process.env.REACT_APP_PUBLIC_BASE_URL}users/token/verify/`
    )
      throw error;
    if (error.response.status !== 401) throw error;
    // Token verification failed, attempt to refresh the token
    await refreshAccessToken();
    // Retry the original request with the new token
    const newRequest = { ...error.config, _retry: true };
    return api(newRequest);
  }
);
