import React from "react";
import Form from "./components/Form";
import { useUserManagement } from "hooks/user-management/useUserManagement";
import AuthLayout from "components/Layouts/Auth";
import { Link } from "react-router-dom";
import { PATHS } from "constants/paths";
import { LOADINGSTATE } from "state/loading/types";

function Registration() {
  const { createUser, isLoading } = useUserManagement();
  return (
    <AuthLayout>
      <h1 className="text-main text-[32px] font-semibold my-4 mobile:my-2">
        Welcome Dudes
      </h1>
      <Form
        submitHandler={createUser}
        isLoading={isLoading[LOADINGSTATE.USERMANAGEMENT]}
      />

      <p className="mt-3 mobile:mt-2">
        Already have an account?
        <Link className="text-link hover:underline" to={PATHS.LOGIN}>
          {" "}
          Click here to Login!
        </Link>
      </p>
      <p className="mt-3 mobile:mt-2">
        By signing up you agree to our
        <Link className="text-link hover:underline" to={PATHS.TERMS}>
          {" "}
          Terms and Conditions
        </Link>
      </p>
    </AuthLayout>
  );
}

export default Registration;
