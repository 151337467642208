import { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { INTRODATA } from "constants/introducton";
import CarouselActions from "./components/CarouselActions";
import platform from "platform";

function Introduction() {
  const [isLastSlide, setIsLastSlide] = useState(false);

  const isDeviceIPhone = platform.product === "iPhone";

  return (
    <div className={`w-full ${isDeviceIPhone && "safari"}`}>
      <Swiper
        className={`${isDeviceIPhone ? "h-full" : "h-screen"}`}
        slidesPerView={1}
        //@ts-ignore
        onActiveIndexChange={(swiper) => {
          if (swiper.activeIndex === INTRODATA.length - 1) setIsLastSlide(true);
        }}
      >
        {INTRODATA.map((item, index) => (
          <SwiperSlide key={index}>
            <div className="flex-row mx-auto">
              <img
                src={item.img}
                alt="peep"
                className="mx-auto item-center mt-10"
              />
              <p
                className="text-main max-w-[300px] mx-auto text-center"
                style={{ marginBottom: "17%" }}
              >
                {item.text}
              </p>
            </div>
          </SwiperSlide>
        ))}
        <CarouselActions isLastSlide={isLastSlide} />
      </Swiper>
      <div className="bg-lightBlue fixed right-[-60px] bottom-[-60px] rounded-full z-[-1] w-[150px] h-[150px]" />
    </div>
  );
}

export default Introduction;
