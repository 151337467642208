import AuthLayout from "components/Layouts/Auth";
import Form from "./components/Form";
import { useUserManagement } from "hooks/user-management/useUserManagement";
import { useAuthState } from "state/auth/hooks";

const VerifyOTP = () => {
  const { verifyOTP, resendOTP } = useUserManagement();
  const { phone_number } = useAuthState();
  return (
    <AuthLayout>
      <h1 className=" text-[32px] font-semibold my-7 mobile:my-2">
        Enter Validation Code
      </h1>
      <Form
        submitHandler={verifyOTP}
        resendOTP={resendOTP}
        phone_number={phone_number}
      />
    </AuthLayout>
  );
};

export default VerifyOTP;
