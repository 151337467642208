import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import Button from "components/Button";
import { verifyOTPSchema } from "schema/user-management";
import OTPInput from "react-otp-input";
import Error from "components/Error";
import { TVerifyEscalationNumberPayload } from "types/escalation-number";
import { useLoading } from "state/loading/hooks";
import { LOADINGSTATE } from "state/loading/types";

type TProps = {
  id: number;
  phone_number: string;
  resendOTP: (id: number) => Promise<void>;
  submitHandler: (data: TVerifyEscalationNumberPayload) => Promise<void>;
  isResendOtpButtonDisabled: boolean;
  setIsResendOtpButtonDisabled: React.Dispatch<React.SetStateAction<boolean>>;
};
function VerifyOTP({
  phone_number,
  resendOTP,
  submitHandler,
  id,
  isResendOtpButtonDisabled,
  setIsResendOtpButtonDisabled,
}: TProps) {
  const [otp, setOtp] = useState("");
  const { isLoading } = useLoading();
  const { handleSubmit, errors, values, setFieldValue } = useFormik({
    initialValues: {
      otp: "",
      phone_number,
    },
    validationSchema: verifyOTPSchema,
    onSubmit: async (values, { setSubmitting }) => {
      await submitHandler({
        otp: values.otp,
        id,
      });
      setSubmitting(false);
    },
  });

  useEffect(() => {
    if (!otp.length) return;
    setFieldValue("otp", otp);
  }, [otp, setFieldValue]);
  return (
    <form
      noValidate
      onSubmit={handleSubmit}
      className="max-w-[380px] w-full flex flex-col items-center gap-y-6 mt-6"
    >
      <OTPInput
        value={values.otp}
        onChange={setOtp}
        inputType="number"
        numInputs={4}
        renderSeparator={<span className="ml-4"></span>}
        renderInput={(props) => <input {...props} />}
        inputStyle={
          "!w-[82.417px] h-[103.94px] bg-white focus:outline-none font-semibold text-[48px] text-center shadow-input"
        }
      />
      {errors.otp && <Error className="self-start" error={errors.otp} />}
      <Button
        title="Verify validation code"
        className="w-[237px] h-[44px] bg-button"
        type="submit"
        isLoading={isLoading[LOADINGSTATE.VERIFYOTP]}
      />
      <p
        onClick={() => {
          resendOTP(id);
          setIsResendOtpButtonDisabled(true);
        }}
        className={`text-center text-main typography text-sm ${
          isResendOtpButtonDisabled ? "cursor-not-allowed" : "cursor-pointer"
        }`}
      >
        Resend validation code
      </p>
    </form>
  );
}

export default VerifyOTP;
