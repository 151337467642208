import React from "react";
import Error from "components/Error";

interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {
  error?: boolean | undefined | string;
}

const InputCard: React.FC<IProps> = ({
  placeholder,
  onChange,
  type,
  style,
  className,
  value,
  name,
  readOnly,
  onClick,
  pattern = "[0-9]*",
  maxLength = 1,
  inputMode = "numeric",
  error,
  id,
  onKeyDown,
}) => {
  return (
    <div className="flex flex-col gap-y-1">
      <input
        inputMode={inputMode}
        pattern={pattern}
        className={[
          `w-full max-w-[72px] rounded-[3px] outline-none focus:outline-none shadow-input text-[42px] py-5 text-center typography text-main transition-all duration-150 ease-iin cursor-pointer`,
          className,
        ].join(" ")}
        autoComplete="off"
        placeholder={placeholder}
        onChange={onChange}
        name={name}
        readOnly={readOnly}
        id={id ?? name}
        type={type || "text"}
        style={style}
        value={value}
        onClick={onClick}
        maxLength={maxLength}
        onKeyDown={onKeyDown}
      />
      {error && typeof error === "string" && <Error error={error} />}
    </div>
  );
};

export default InputCard;
