import React from "react";
import Loader from "components/Loader/Loader";
interface IProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  isLoading?: boolean;
}
const Button: React.FC<IProps> = ({
  onClick = () => {},
  type = "button",
  title,
  isLoading,
  className,
  disabled,
  style,
}) => {
  return (
    <button
      onClick={onClick}
      disabled={disabled || isLoading}
      type={type}
      style={style}
      className={[
        "disabled:bg-disabled flex items-center justify-center disabled:cursor-not-allowed font-semibold py-3 px-4 rounded-[3px] text-sm text-white",
        className,
      ].join(" ")}
    >
      {isLoading && <Loader style={{ color: "white" }} />}
      {title}
    </button>
  );
};

export default Button;
