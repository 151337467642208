import React from "react";
import Button from "components/Button";
import Input from "components/Input";
import PhoneInput from "components/Input/PhoneNumber";
import { useFormik } from "formik";
import { loginSchema } from "schema/auth";
import { LOGININITIALVALUES } from "constants/form";
import { TLoginPayload } from "types/auth";

type TProps = {
  submitHandler: (arg: TLoginPayload) => Promise<void>;
  isLoading: boolean;
};

function Form({ submitHandler, isLoading }: TProps) {
  const { handleSubmit, handleChange, values, touched, errors, setFieldValue } =
    useFormik({
      initialValues: LOGININITIALVALUES,
      validationSchema: loginSchema,
      onSubmit: async (values, { setSubmitting }) => {
        await submitHandler(values);
        setSubmitting(false);
      },
    });

  return (
    <form
      noValidate
      onSubmit={handleSubmit}
      className="flex flex-col gap-y-4 mobile:gap-y-2 items-center justify-center w-full max-w-[370px]"
    >
      <PhoneInput
        setValue={setFieldValue}
        name="phone_number"
        className="w-full"
        error={touched.phone_number && errors.phone_number}
      />
      <Input
        placeholder="Password"
        className="w-full"
        error={touched.password && errors.password}
        onChange={handleChange}
        value={values.password}
        name="password"
        type="password"
      />
      <Button
        title="Login"
        className="w-[237px] bg-button my-2"
        type="submit"
        isLoading={isLoading}
      />
    </form>
  );
}

export default Form;
