import { useCallback } from "react";
import { api } from "config/api";
import {
  TVerifyEscalationNumberPayload,
  TAddEscalationNumberPayload,
} from "types/escalation-number";
import { useLoading } from "state/loading/hooks";
import { LOADINGSTATE } from "state/loading/types";
import { useUser } from "state/user/hooks";
import { handleServerError } from "utils/error";
import { toast } from "react-toastify";
import { useUserManagement } from "hooks/user-management/useUserManagement";
import { MODALS, useModal } from "context/Modal";

export const useEscalationPhoneNumber = () => {
  const { setIsLoading } = useLoading();
  const { getUserInfo } = useUserManagement();
  const { setEscalationNumber, user } = useUser();
  const { handleClose } = useModal();

  const deleteEscalationNumber = useCallback(
    async (id: number) => {
      try {
        setIsLoading({ [LOADINGSTATE.ESCALATIONNUMBER]: true });
        await api.delete(`users/me/escalation_phonenumbers/${id}/`);
        getUserInfo();
        toast.success("Number deleted successfully");
      } catch (e) {
        handleServerError(e);
      } finally {
        setIsLoading({ [LOADINGSTATE.ESCALATIONNUMBER]: false });
      }
    },
    [setIsLoading, getUserInfo]
  );

  const addEscalationNumber = useCallback(
    async (payload: TAddEscalationNumberPayload) => {
      try {
        setIsLoading({ [LOADINGSTATE.ESCALATIONNUMBER]: true });
        if (!user) return;
        if (payload.id) await deleteEscalationNumber(payload.id);
        const { data } = await api.post("users/me/escalation_phonenumbers/", {
          phone_number: payload.phone_number,
          name: user.name,
        });
        if (!data) return;
        //filter if number with same id is already there
        const escalationNumbers = user.escalation_phonenumbers.filter(
          ({ id }) => id !== payload.id
        );
        //add the new result in the array
        escalationNumbers.push(data);
        setEscalationNumber(escalationNumbers);
        toast.success("Validation code sent to your escalation number.");
        return data;
      } catch (e) {
        handleServerError(e);
      } finally {
        setIsLoading({ [LOADINGSTATE.ESCALATIONNUMBER]: false });
      }
    },
    [setIsLoading, setEscalationNumber, user, deleteEscalationNumber]
  );

  const verifyEscalationNumber = useCallback(
    async (payload: TVerifyEscalationNumberPayload) => {
      try {
        setIsLoading({ [LOADINGSTATE.VERIFYOTP]: true });
        const { data } = await api.post(
          `users/me/escalation_phonenumbers/${payload.id}/verify_otp/`,
          {
            otp: payload.otp,
          }
        );
        if (!data) return;
        if (!data.message) return;
        toast.success(data.message);
        await getUserInfo();
        setTimeout(() => {
          handleClose(MODALS.UPDATENUMBER);
        }, 2000);
      } catch (e) {
        handleServerError(e);
      } finally {
        setIsLoading({ [LOADINGSTATE.VERIFYOTP]: false });
      }
    },
    [setIsLoading, getUserInfo, handleClose]
  );
  const resendOTP = useCallback(
    async (id: number) => {
      try {
        setIsLoading({ [LOADINGSTATE.RESENDOTP]: true });
        const { data } = await api.post(
          `users/me/escalation_phonenumbers/${id}/resend_otp/`,
          {}
        );
        if (!data) return;
        if (!data.message) return;
        toast.success(data.message);
      } catch (e) {
        handleServerError(e);
      } finally {
        setIsLoading({ [LOADINGSTATE.RESENDOTP]: false });
      }
    },
    [setIsLoading]
  );

  return {
    addEscalationNumber,
    resendOTP,
    verifyEscalationNumber,
    deleteEscalationNumber,
  };
};
