export const PATHS = {
  HOME: "/",
  DASHBOARD: "/dashboard",
  REGISTRATION: "/registration",
  FORGOTPASSWORD: "/forgot_password",
  RESETPASSWORD: "/reset_password",
  SETTINGS: "/settings",
  LOGIN: "/login",
  SUBMIT: "/submit_selfie",
  ALARM: "/alarm",
  VERIFYOTP: "/registration/verify",
  CALL: "/alarm/call",
  TERMS: "/terms",
};
