import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useLoading } from "state/loading/hooks";
import { useUser } from "state/user/hooks";
import { api } from "config/api";
import {
  TCreateUserPayload,
  TUpdateUserPayload,
  TVerifyOtpPayload,
} from "types/user-management";
import { toast } from "react-toastify";
import { LOADINGSTATE } from "state/loading/types";
import { PATHS } from "constants/paths";
import { useAuthState } from "state/auth/hooks";
import { handleServerError } from "utils/error";

export const useUserManagement = () => {
  const { isLoading, setIsLoading } = useLoading();
  const { setUser } = useUser();
  const navigate = useNavigate();
  const { setPhoneNumber } = useAuthState();

  const getUserInfo = useCallback(async () => {
    try {
      setIsLoading({ [LOADINGSTATE.USERMANAGEMENT]: true });
      const { data } = await api.get("users/me/");
      if (!data) return;
      setUser(data);
    } catch (e) {
      handleServerError(e);
    } finally {
      setIsLoading({ [LOADINGSTATE.USERMANAGEMENT]: false });
    }
  }, [setIsLoading, setUser]);

  const updateUserInfo = useCallback(
    async (payload: TUpdateUserPayload) => {
      try {
        setIsLoading({ [LOADINGSTATE.USERMANAGEMENT]: true });
        const { data } = await api.patch("users/me/", payload);
        if (!data) return;
        setUser(data);
        toast.success("User information updated successfully");
      } catch (e) {
        handleServerError(e);
      } finally {
        setIsLoading({ [LOADINGSTATE.USERMANAGEMENT]: false });
      }
    },
    [setIsLoading, setUser]
  );

  const createUser = useCallback(
    async (payload: TCreateUserPayload) => {
      try {
        setIsLoading({ [LOADINGSTATE.USERMANAGEMENT]: true });
        const { data } = await api.post("users/", payload);
        if (!data) return;
        toast.success("Account creation complete. Verify to get started.");
        setPhoneNumber(payload.phone_number);
        navigate(PATHS.VERIFYOTP);
      } catch (e) {
        handleServerError(e);
      } finally {
        setIsLoading({ [LOADINGSTATE.USERMANAGEMENT]: false });
      }
    },
    [navigate, setPhoneNumber, setIsLoading]
  );
  const verifyOTP = useCallback(
    async (payload: TVerifyOtpPayload) => {
      try {
        setIsLoading({ [LOADINGSTATE.VERIFYOTP]: true });
        const { data } = await api.post("users/me/verify_otp/", payload);
        if (!data) return;
        if (!data.message) return;
        toast.success(data.message);
        navigate(PATHS.LOGIN);
      } catch (e) {
        handleServerError(e);
      } finally {
        setIsLoading({ [LOADINGSTATE.VERIFYOTP]: false });
      }
    },
    [navigate, setIsLoading]
  );
  const resendOTP = useCallback(
    async (phone_number: string) => {
      try {
        setIsLoading({ [LOADINGSTATE.RESENDOTP]: true });
        const { data } = await api.post("users/me/resend_otp/", {
          phone_number,
        });
        if (!data) return;
        if (!data.message) return;
        toast.success(data.message);
      } catch (e) {
        handleServerError(e);
      } finally {
        setIsLoading({ [LOADINGSTATE.RESENDOTP]: false });
      }
    },
    [setIsLoading]
  );

  return {
    updateUserInfo,
    createUser,
    getUserInfo,
    verifyOTP,
    resendOTP,
    isLoading,
  };
};
