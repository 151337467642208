import { DAYS } from "constants/index";
import { TAlarm } from "state/dashboard/types";
import { DAYPERIOD, TAlarmsResponse } from "types/alarm";

export const convertTo24HourFormat = (time12Hour: string) => {
  const [time, period] = time12Hour.split(" ");
  const [hours, minutes] = time.split(":");

  let hours24 = parseInt(hours, 10);

  if (period === "PM" && hours24 !== 12) {
    hours24 += 12;
  } else if (period === "AM" && hours24 === 12) {
    hours24 = 0;
  }

  const formattedTime = `${hours24.toString().padStart(2, "0")}:${minutes}`;
  return formattedTime;
};

export const parseResponse = (response: TAlarmsResponse[]) => {
  let result: TAlarm[] = [];
  for (const data of response) {
    for (const alarm of data.alarms) {
      const res = {
        id: data.id,
        day: alarm.day,
        time: convertTo12HourFormat(alarm.time),
      };
      result.push(res);
    }
  }
  return result;
};

export const convertTo12HourFormat = (time24Hour: string) => {
  const [hours, minutes] = time24Hour.split(":");

  const date = new Date();
  date.setHours(parseInt(hours, 10));
  date.setMinutes(parseInt(minutes, 10));

  const time12Hour = new Intl.DateTimeFormat("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  }).format(date);
  return time12Hour;
};
export const breakdownTime = (time12Hour: string) => {
  const [time, dayPeriod] = time12Hour.split(" ");

  const [hours, minutes] = time.split(":").map((part) => parseInt(part, 10));

  const hourFirstDigit = Math.floor(hours / 10);
  const hourSecondDigit = hours % 10;
  const minuteFirstDigit = Math.floor(minutes / 10);
  const minuteSecondDigit = minutes % 10;
  return {
    hourFirstDigit: isNaN(hourFirstDigit) ? 0 : hourFirstDigit,
    hourSecondDigit: isNaN(hourSecondDigit) ? 0 : hourSecondDigit,
    minuteFirstDigit: isNaN(minuteFirstDigit) ? 0 : minuteFirstDigit,
    minuteSecondDigit: isNaN(minuteSecondDigit) ? 0 : minuteSecondDigit,
    dayPeriod: dayPeriod || DAYPERIOD.AM,
  };
};
export const getDay = (dayIndex: number) => {
  const day = DAYS.find(({ value }) => value === dayIndex);
  if (!day) return "";
  return day.label;
};
export const getDayPeriod = (time: string) => {
  return time.includes("AM") ? DAYPERIOD.AM : DAYPERIOD.PM;
};
