import React, { Fragment } from "react";
import { peoples } from "assets";
import platform from "platform";

type TProps = {
  children: React.ReactNode;
};

function AuthLayout({ children }: TProps) {
  const isDeviceIPhone = platform.product === "iPhone";

  return (
    <Fragment>
      <div
        className={`flex flex-col items-center w-full mobile:h-[calc(100vh_-_100px)] ${
          isDeviceIPhone && "mobile:h-[calc(100vh_-_120px)]"
        }`}
      >
        <img
          src={peoples}
          alt="peoples_image"
          className="w-full object-cover max-w-[413px] mobile:max-h-[313px]"
        />
        <div className="px-2 flex flex-col items-center w-full">{children}</div>
      </div>
      <div className="bg-lightBlue fixed left-[-75px] bottom-[-45px] rounded-full z-[-1] w-[150px] h-[150px]" />
    </Fragment>
  );
}

export default AuthLayout;
