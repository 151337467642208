import { TSetAlarmSchema, TUpdateAlarmSchema } from "types/alarm";
import { TLoginPayload, TResetPasswordPayload } from "types/auth";
import { TRegistrationSchema, TForgotPasswordSchema } from "types/schema";

export const LOGININITIALVALUES: TLoginPayload = {
  phone_number: "",
  password: "",
};
export const FORGOTPASSWORDINITIALVALUES: TForgotPasswordSchema = {
  phone_number: "",
};
export const RESETPASSWORDINITIALVALUES: TResetPasswordPayload = {
  phone_number: "",
  temp_password: "",
  new_password: "",
};
export const REGISTRATIONINITIALVALUES: TRegistrationSchema = {
  name: "",
  phone_number: "",
  password: "",
};

export const SETALARMINITIALVALUES: TSetAlarmSchema = {
  hourFirstDigit: "",
  hourSecondDigit: "",
  minuteFirstDigit: "",
  minuteSecondDigit: "",
  dayPeriod: "AM",
  day: 0,
};

export const UPDATEALARMINIITALVALUES: TUpdateAlarmSchema = {
  hourFirstDigit: "",
  hourSecondDigit: "",
  minuteFirstDigit: "",
  minuteSecondDigit: "",
  dayPeriod: "AM",
};
