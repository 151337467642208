import { InferType } from "yup";
import { setAlarmSchema, updateAlarmSchema } from "schema/alarm";

export type TSetAlarmSchema = InferType<typeof setAlarmSchema>;
export type TUpdateAlarmSchema = InferType<typeof updateAlarmSchema>;

export type TCreateAlarmPayload = {
  time: string;
  day: number;
};
export type TUpdateAlarmPayload = {
  id: number;
  time: string;
  day: number;
};
export type TAlarmsResponse = {
  id: number;
  title: string;
  alarms: { day: number; time: string }[];
};

export enum DAYPERIOD {
  AM = "AM",
  PM = "PM",
}
