import React from "react";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "state/store";
import Router from "routes/routes";
import Toast from "components/Notification";
import AppProvider from "context/App";
import ModalProvider from "context/Modal";
function App() {
  return (
    <BrowserRouter>
      <Provider store={store}>
        <AppProvider>
          <ModalProvider>
            <Toast />
            <Router />
          </ModalProvider>
        </AppProvider>
      </Provider>
    </BrowserRouter>
  );
}

export default App;
