import Error from "components/Error";
import React from "react";

interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {
  error?: string | boolean | undefined;
  edit?: boolean;
}

const Input: React.FC<IProps> = ({
  placeholder,
  onChange,
  error,
  type,
  className,
  value,
  name,
  readOnly,
}) => {
  return (
    <div className={["", className].join(" ")}>
      <input
        autoComplete="off"
        placeholder={placeholder}
        onChange={onChange}
        name={name}
        readOnly={readOnly}
        id={name}
        type={type}
        value={value}
        className={
          "p-3 max-w-full focus:outline-none bg-white h-[47px] w-full rounded-md text-main shadow-input"
        }
      />
      {error && typeof error == "string" && <Error error={error} />}
    </div>
  );
};

export default Input;
