import React, { useCallback } from "react";
import Input from "pages/App/Dashboard/components/SetAlarm/OptionsPanel/Input";
import Button from "components/Button";
import { useFormik } from "formik";
import { convertTo24HourFormat } from "utils/alarm";
import { updateAlarmSchema } from "schema/alarm";
import { TUpdateAlarmPayload } from "types/alarm";
import { MODALS, useModal } from "context/Modal";

type TProps = {
  submitHandler: (payload: TUpdateAlarmPayload) => Promise<void>;
  deleteAlarm: (id: number) => Promise<void>;
  alarm: {
    hourFirstDigit: number;
    hourSecondDigit: number;
    minuteFirstDigit: number;
    minuteSecondDigit: number;
    dayPeriod: string;
    day: number;
    id: number;
  };
  isLoading: boolean;
};
function Form({ submitHandler, alarm, isLoading, deleteAlarm }: TProps) {
  const { handleClose } = useModal();
  const { handleChange, handleSubmit, setFieldValue, values, errors, touched } =
    useFormik({
      initialValues: {
        hourFirstDigit: alarm.hourFirstDigit,
        hourSecondDigit: alarm.hourSecondDigit,
        minuteFirstDigit: alarm.minuteFirstDigit,
        minuteSecondDigit: alarm.minuteSecondDigit,
        dayPeriod: alarm.dayPeriod,
      },
      validationSchema: updateAlarmSchema,
      onSubmit: async (values, { setSubmitting }) => {
        const {
          hourFirstDigit,
          hourSecondDigit,
          minuteFirstDigit,
          minuteSecondDigit,
          dayPeriod,
        } = values;
        const time12Hour = `${
          hourFirstDigit ?? "0"
        }${hourSecondDigit}:${minuteFirstDigit}${minuteSecondDigit} ${dayPeriod}`;
        const time24Hour = convertTo24HourFormat(time12Hour);
        await submitHandler({
          time: time24Hour,
          day: alarm.day,
          id: alarm.id,
        });
        handleClose(MODALS.UPDATEALARM);
        setSubmitting(false);
      },
    });

  const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, id } = e.target;
    const index = Number(id.split("-")[1]);
    handleChange(e);
    if (!value.length) return;
    if (index > 3) return;
    const nextField: HTMLInputElement | null = document.querySelector(
      `#modalField-${index + 1}`
    );
    if (!nextField) return;
    nextField.focus();
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const { code } = e;
    //@ts-ignore
    const { id, value } = e.target;
    const index = Number(id.split("-")[1]);
    //first element
    if (value.length) return;
    if (index === 1) return;
    if (code === "Backspace") {
      const prevField: HTMLInputElement | null = document.querySelector(
        `#modalField-${index - 1}`
      );
      if (!prevField) return;
      prevField.focus();
    }
  };
  const handleDayPeriod = useCallback(() => {
    if (values.dayPeriod === "AM") {
      setFieldValue("dayPeriod", "PM");
    } else {
      setFieldValue("dayPeriod", "AM");
    }
  }, [values, setFieldValue]);

  return (
    <form
      noValidate
      className="flex flex-col gap-y-10 items-center"
      onSubmit={handleSubmit}
    >
      <div className="flex gap-x-2 items-center">
        <Input
          pattern="\d*\.?\d*"
          id="modalField-1"
          name="hourFirstDigit"
          value={values.hourFirstDigit}
          onChange={handleFieldChange}
          onKeyDown={handleKeyPress}
          className="bg-white"
          error={touched.hourFirstDigit && errors.hourFirstDigit}
        />
        <Input
          name="hourSecondDigit"
          id="modalField-2"
          value={values.hourSecondDigit}
          onChange={handleFieldChange}
          onKeyDown={handleKeyPress}
          className="bg-white"
          error={touched.hourSecondDigit && errors.hourSecondDigit}
        />
        <span className="font-extrabold text-5xl typography">:</span>
        <Input
          name="minuteFirstDigit"
          id="modalField-3"
          value={values.minuteFirstDigit}
          onChange={handleFieldChange}
          onKeyDown={handleKeyPress}
          className="bg-white"
          error={touched.minuteFirstDigit && errors.minuteFirstDigit}
        />
        <Input
          name="minuteSecondDigit"
          id="modalField-4"
          value={values.minuteSecondDigit}
          onChange={handleFieldChange}
          onKeyDown={handleKeyPress}
          className="bg-white"
          error={touched.minuteSecondDigit && errors.minuteSecondDigit}
        />
        <Input
          value={values.dayPeriod}
          name="dayPeriod"
          onClick={handleDayPeriod}
          className="ml-4"
          error={touched.dayPeriod && errors.dayPeriod}
          readOnly
        />
      </div>
      <p className="text-center font-bold bg-[#FFFF00]"><i>Important: turn on your phone’s ring mode and volume in order to hear the wake up call!</i></p>
      <div className="flex gap-x-5">
        <Button
          title="Update Alarm"
          type="submit"
          isLoading={isLoading}
          className="bg-button typography max-w-[180px]"
        />
        <Button
          title="Delete Alarm"
          onClick={() => {
            deleteAlarm(alarm.id);
            handleClose(MODALS.UPDATEALARM);
          }}
          className="bg-[#F8832E] typography max-w-[180px]"
        />
      </div>
    </form>
  );
}

export default Form;
