import React from "react";

type TProps = {
  children: React.ReactNode;
  className?: string;
};

function CardLayout({ children, className }: TProps) {
  return (
    <div
      className={["rounded-[3px] bg-white w-full shadow-input", className].join(
        " "
      )}
    >
      {children}
    </div>
  );
}

export default CardLayout;
