import React from "react";
import { edit_icon, delete_icon } from "assets";

interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  error?: string | boolean;
  isEditable?: boolean;
  isDeletable?: boolean;
  onDelete?: () => void;
}

const EditInput: React.FC<IProps> = ({
  label = "",
  placeholder,
  onClick,
  onChange,
  error,
  type,
  className,
  value,
  onFocus,
  name,
  readOnly = false,
  isEditable = false,
  isDeletable = false,
  onDelete,
}) => {
  return (
    <div className={className} onClick={onClick}>
      <label
        className="text-base font-semibold font-sans text-main mb-3 block"
        htmlFor={name}
      >
        {label}
      </label>
      <div
        className="flex justify-between items-center w-full border rounded-[3px] bg-white py-4 px-5"
        style={{ boxShadow: "0px 4px 15px 0px rgba(0, 0, 0, 0.10)" }}
      >
        <input
          autoComplete="off"
          placeholder={placeholder}
          onChange={onChange}
          name={name}
          onFocus={onFocus}
          readOnly={readOnly}
          id={name}
          type={type || "text"}
          value={value}
          className="text-black font-sans font-bold w-full bg-transparent focus:outline-none"
        />
        {isEditable && (
          <label htmlFor={name}>
            <img
              src={edit_icon}
              alt="edit"
              className="w-5 h-5 cursor-pointer"
            />
          </label>
        )}
        {isDeletable && (
          <img
            src={delete_icon}
            alt="edit"
            className="w-5 h-5 cursor-pointer ml-2"
            onClick={onDelete}
          />
        )}
      </div>
      {error && <p className="font-sans text-sm text-red mt-2">{error}</p>}
    </div>
  );
};

export default EditInput;
