import React from "react";

type TProps = {
  className?: string;
  error: string;
};
function Error({ error, className }: TProps) {
  return (
    <p className={["text-sm font-helvetica text-red", className].join(" ")}>
      {error}
    </p>
  );
}

export default Error;
