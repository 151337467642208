import React, { useCallback, useEffect } from "react";
import Input from "./Input";
import Button from "components/Button";
import { DAYS } from "constants/index";
import { useFormik } from "formik";
import { SETALARMINITIALVALUES } from "constants/form";
import { setAlarmSchema } from "schema/alarm";
import { convertTo24HourFormat } from "utils/alarm";
import { TCreateAlarmPayload } from "types/alarm";
import { MODALS, useModal } from "context/Modal";

type TProps = {
  submitHandler: (payload: TCreateAlarmPayload) => Promise<void>;
  scheduledDays: number[];
  isLoading: boolean;
};
function SetAlarmOptionsPanel({
  submitHandler,
  scheduledDays,
  isLoading,
}: TProps) {
  const { handleClose } = useModal();

  const { handleChange, handleSubmit, setFieldValue, values, errors, touched } =
    useFormik({
      initialValues: SETALARMINITIALVALUES,
      validationSchema: setAlarmSchema,
      onSubmit: async (values, { setSubmitting, resetForm }) => {
        const {
          hourFirstDigit,
          hourSecondDigit,
          minuteFirstDigit,
          minuteSecondDigit,
          dayPeriod,
          day,
        } = values;
        const time12Hour = `${
          hourFirstDigit ?? "0"
        }${hourSecondDigit}:${minuteFirstDigit}${minuteSecondDigit} ${dayPeriod}`;
        const time24Hour = convertTo24HourFormat(time12Hour);
        await submitHandler({
          time: time24Hour,
          day,
        });
        handleClose(MODALS.SETALARM);
        setSubmitting(false);
        resetForm();
      },
    });

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const { code } = e;
    //@ts-ignore
    const { id, value } = e.target;
    const index = Number(id.split("-")[1]);
    //first element
    if (value.length) return;
    if (index === 1) return;
    if (code === "Backspace") {
      const prevField: HTMLInputElement | null = document.querySelector(
        `#field-${index - 1}`
      );
      if (!prevField) return;
      prevField.focus();
    }
  };
  const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, id } = e.target;
    const index = Number(id.split("-")[1]);
    handleChange(e);
    if (!value.length) return;
    if (index > 3) return;
    const nextField: HTMLInputElement | null = document.querySelector(
      `#field-${index + 1}`
    );
    if (!nextField) return;
    nextField.focus();
  };
  const handleDayPeriod = useCallback(() => {
    if (values.dayPeriod === "AM") {
      setFieldValue("dayPeriod", "PM");
    } else {
      setFieldValue("dayPeriod", "AM");
    }
  }, [values, setFieldValue]);
  useEffect(() => {
    const defaultSelectedDay = DAYS.filter(
      ({ value }) => !scheduledDays.includes(value)
    )[0];
    setFieldValue("day", defaultSelectedDay.value);
  }, [setFieldValue, scheduledDays]);
  return (
    <form
      noValidate
      className="flex flex-col gap-y-10 w-fit mx-auto"
      onSubmit={handleSubmit}
    >
      <div className="flex gap-x-2 items-center">
        <Input
          pattern="\d*\.?\d*"
          id="field-1"
          name="hourFirstDigit"
          value={values.hourFirstDigit}
          onChange={handleFieldChange}
          className="bg-white"
          error={touched.hourFirstDigit && errors.hourFirstDigit}
          onKeyDown={handleKeyPress}
        />
        <Input
          name="hourSecondDigit"
          id="field-2"
          value={values.hourSecondDigit}
          onChange={handleFieldChange}
          className="bg-white"
          error={touched.hourSecondDigit && errors.hourSecondDigit}
          onKeyDown={handleKeyPress}
        />
        <span className="font-extrabold text-5xl typography">:</span>
        <Input
          name="minuteFirstDigit"
          id="field-3"
          value={values.minuteFirstDigit}
          onChange={handleFieldChange}
          className="bg-white"
          error={touched.minuteFirstDigit && errors.minuteFirstDigit}
          onKeyDown={handleKeyPress}
        />
        <Input
          name="minuteSecondDigit"
          id="field-4"
          value={values.minuteSecondDigit}
          onChange={handleFieldChange}
          className="bg-white"
          error={touched.minuteSecondDigit && errors.minuteSecondDigit}
          onKeyDown={handleKeyPress}
        />
        <Input
          value={values.dayPeriod}
          name="dayPeriod"
          onClick={handleDayPeriod}
          className="ml-4"
          error={touched.dayPeriod && errors.dayPeriod}
          readOnly
        />
      </div>
      <div className="flex gap-x-2">
        {DAYS.filter(({ value }) => !scheduledDays.includes(value)).map(
          ({ label, value }, index) => (
            <Input
              key={index}
              name="day"
              value={label.substring(0, 3)}
              readOnly
              className={`hover:bg-[#90ABE3] hover:text-white day-input ${
                values.day === value && "bg-[#90ABE3] text-white "
              }`}
              onClick={() => {
                setFieldValue("day", value);
              }}
              error={touched.day && errors.day}
            />
          )
        )}
      </div>
      <p className="text-center font-bold bg-[#FFFF00]"><i>Important: turn on your phone’s ring mode and volume in order to hear the wake up call!</i></p>
      <Button
        title="Save Alarm"
        type="submit"
        className="bg-button typography w-[180px] mx-auto"
        isLoading={isLoading}
      />
    </form>
  );
}

export default SetAlarmOptionsPanel;
