import { TEscalationNumber } from "state/user/types";
import { TPartialEscalationNumber } from "types/escalation-number";

export const createEscalationNumberState = (
  payload: TEscalationNumber[]
): TPartialEscalationNumber[] => {
  if (!payload.length) return [{ is_phn_verified: false, phone_number: "" }];
  return payload.map(({ id, is_phn_verified, phone_number }) => {
    return {
      id,
      is_phn_verified,
      phone_number,
    };
  });
};
