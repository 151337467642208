import React from "react";
import { PATHS } from "constants/paths";
import { Link, useNavigate } from "react-router-dom";
import { useUser } from "state/user/hooks";

function TermsAndCondition() {
  const { user } = useUser();
  const navigate = useNavigate();
  return (
    <div className="container mx-auto p-4">
      <h1 className="text-3xl font-bold mb-4">Terms and Conditions</h1>
      <p className="mb-4">
        <strong>Consent to Receive SMS Messages and Calls.</strong> You consent
        to receive SMS messages (including text messages), and telephone calls
        (including prerecorded and artificial voice and autodialed) from us, our
        agents, representatives, affiliates, or anyone communicating on our
        behalf at the specific number(s) you have provided to us, with
        service-related information, questions about your use of the Services,
        and/or Even Account and marketing information.
      </p>

      <p className="mb-4">
        You certify, warrant, and represent that the telephone number you have
        provided to us is your contact number and not someone else’s. You
        represent that you are permitted to receive calls and text messages at
        the telephone number you have provided to us. You agree to promptly
        alert us whenever you stop using a telephone number. Even and our
        agents, representatives, affiliates, and anyone calling on our behalf
        may use such means of communication described in this section even if
        you will incur costs to receive such phone messages, text messages,
        e-mails, or other means.
      </p>

      <p className="mb-4">
        Standard message and data rates may apply to all SMS messages (including
        text messages). We may modify or terminate our SMS messaging services
        from time to time, for any reason, and without notice, including the
        right to terminate SMS messaging with or without notice, without
        liability to you.
      </p>
      <button
        onClick={() => navigate(user ? PATHS.DASHBOARD : PATHS.REGISTRATION)}
        className="block mt-10 typography w-[180px] mx-auto py-2 px-3 rounded-[3px] text-base text-link border-[#2A3D6F] border-solid border-2
        transition-all duration-200 hover:bg-button hover:text-white
          "
      >
        Go Back
      </button>
    </div>
  );
}

export default TermsAndCondition;
