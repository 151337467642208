import React from "react";
import Form from "./components/Form";
import { useAuth } from "hooks/auth/useAuth";
import AuthLayout from "components/Layouts/Auth";
import { Link } from "react-router-dom";
import { PATHS } from "constants/paths";

function Login() {
  const { loginUser, isLoading } = useAuth();
  return (
    <AuthLayout>
      <h1 className="text-main text-[32px] font-semibold my-4 mobile:my-2">
        Welcome Dudes
      </h1>
      <Form submitHandler={loginUser} isLoading={isLoading.auth} />
      <Link
        className="text-link mt-3 mobile:mt-2 hover:underline"
        to={PATHS.FORGOTPASSWORD}
      >
        {" "}
        Forgot Password?
      </Link>
      <p className="mt-3 mobile:mt-2">
        Don't have an account?
        <Link className="text-link hover:underline" to={PATHS.REGISTRATION}>
          {" "}
          Click here to Register!
        </Link>
      </p>
    </AuthLayout>
  );
}

export default Login;
