import { createSlice } from "@reduxjs/toolkit";
import { TAuthSlice } from "./types";

const initialState: TAuthSlice = {
  phone_number: undefined,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setPhoneNumberAction: (
      state,
      action: {
        payload: string | undefined;
      }
    ) => ({
      ...state,
      phone_number: action.payload,
    }),
  },
});

export const { setPhoneNumberAction } = authSlice.actions;

export default authSlice.reducer;
