import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { v4 as uuidv4 } from "uuid";
import { RESENDOTPCOOLDOWNTIME } from "constants/index";
import Input from "pages/App/Settings/components/Form/Input";
import PhoneInput from "components/Input/PhoneNumber";
import Button from "components/Button";
import VerifyOTP from "./Verify";
import Modal from "components/Modal";
import { MODALS, useModal } from "context/Modal";
import { useFormik } from "formik";
import { updateUserEscalationNumberSchema } from "schema/user-management";
import { TEscalationNumber } from "state/user/types";
import { TPartialEscalationNumber } from "types/escalation-number";
import { createEscalationNumberState } from "utils/escalation_number";
import { useEscalationPhoneNumber } from "hooks/escalation_phone_number/useEscalationPhoneNumber";
import parsePhoneNumber from "libphonenumber-js";

type TProps = {
  value: TEscalationNumber[];
  isLoading: boolean;
};
function EscalationNumber({ value, isLoading }: TProps) {
  const { handleOpen, isModalOpen } = useModal();
  const [isShowOTP, setIsShowOTP] = useState(false);
  const [isResendOtpButtonDisabled, setIsResendOtpButtonDisabled] =
    useState(false);
  const [escalation_numbers, setEscalationNumbers] = useState<
    TPartialEscalationNumber[]
  >([]);
  const [selectedEscalationNumber, setSelectedEscalationNumber] = useState<
    TPartialEscalationNumber | undefined
  >();
  const {
    verifyEscalationNumber,
    resendOTP,
    addEscalationNumber,
    deleteEscalationNumber,
  } = useEscalationPhoneNumber();
  const {
    handleSubmit,
    touched,
    errors,
    setFieldValue,
    setFieldTouched,
    setFieldError,
  } = useFormik({
    initialValues: {
      phone_number: "",
    },
    validationSchema: updateUserEscalationNumberSchema,
    onSubmit: async (values, { setSubmitting }) => {
      const data = await addEscalationNumber({
        phone_number: values.phone_number,
        id: selectedEscalationNumber?.id,
      });
      if (!data.id) return;
      setSubmitting(false);
      setIsResendOtpButtonDisabled(true);
      setSelectedEscalationNumber({
        id: data.id,
        phone_number: data.phone_number,
        is_phn_verified: false,
      });
      setIsShowOTP(true);
    },
  });

  const showVerifyOTP = useMemo(() => {
    if (!selectedEscalationNumber) return false;
    if (!selectedEscalationNumber.id) return false;
    if (selectedEscalationNumber.is_phn_verified) return false;
    if (isShowOTP) return false;
    return true;
  }, [selectedEscalationNumber, isShowOTP]);
  //! USER CANNOT ADD MORE THAN ONE ESCALATION NUMER ATM.
  // const addNewField = useCallback(() => {
  //   setEscalationNumbers((prev) => [
  //     ...prev,
  //     {
  //       is_phn_verified: false,
  //       phone_number: "",
  //     },
  //   ]);
  // }, []);
  const editNumber = useCallback(
    (data: TPartialEscalationNumber) => {
      setSelectedEscalationNumber(data);
      handleOpen(MODALS.UPDATENUMBER);
      setFieldValue("phone_number", data.phone_number);
      setFieldTouched("phone_number", false);
      setFieldError("phone_number", "");
    },
    [
      handleOpen,
      setFieldValue,
      setSelectedEscalationNumber,
      setFieldTouched,
      setFieldError,
    ]
  );
  const deleteNumber = useCallback(
    async (id: number) => {
      await deleteEscalationNumber(id);
    },
    [deleteEscalationNumber]
  );

  useEffect(() => {
    setEscalationNumbers(createEscalationNumberState(value));
  }, [value]);

  //close otp form when modal is closed
  useEffect(() => {
    if (isModalOpen[MODALS.UPDATENUMBER]) return;
    setTimeout(() => setIsShowOTP(false), 1000);
  }, [isModalOpen, setIsShowOTP]);

  //enable resend otp button after 2.5mins
  useEffect(() => {
    setTimeout(
      () => setIsResendOtpButtonDisabled(false),
      RESENDOTPCOOLDOWNTIME
    );
  }, [isResendOtpButtonDisabled]);

  useEffect(() => {
    if (isModalOpen[MODALS.UPDATENUMBER]) return;
    if (!selectedEscalationNumber || !selectedEscalationNumber.id) return;
    const phoneNumber = escalation_numbers.find(
      ({ id }) => id === selectedEscalationNumber.id
    );
    if (!phoneNumber) return;
    if (phoneNumber.is_phn_verified) return;
    else deleteNumber(selectedEscalationNumber.id);
  }, [isModalOpen, selectedEscalationNumber, deleteNumber, escalation_numbers]);
  return (
    <Fragment>
      {escalation_numbers.map(({ id, phone_number, is_phn_verified }) => {
        return (
          <Input
            key={id ?? uuidv4()}
            value={parsePhoneNumber(phone_number)?.formatInternational()}
            name={`escalation_number-${uuidv4()}`}
            id={`escalation_number-${uuidv4()}`}
            label="Escalation Phone No"
            placeholder="Phone No"
            className="w-full"
            onFocus={() => {
              if (is_phn_verified) return;
              editNumber({
                id,
                phone_number,
                is_phn_verified,
              });
            }}
            readOnly
            isEditable={!is_phn_verified}
            isDeletable={Boolean(id)}
            onDelete={() => {
              if (!id) return;
              deleteNumber(id);
            }}
          />
        );
      })}
      {/* ADD NEW FIELD BUTTON COMMENTED OUT TEMPORARILY. */}
      {/* <button
        className="flex flex-row items-center self-start gap-x-2 outline-none bg-none focus:outline-none font-sans font-medium text-main disabled:opacity-50 text-sm"
        type="button"
        disabled={escalation_numbers.length === 2}
        onClick={addNewField}
      >
        <img src={plus_icon} alt="plus_icon" className="h-5 w-5" /> Add new
        field
      </button> */}
      <Modal className="max-w-[400px]" modal={MODALS.UPDATENUMBER}>
        <form
          noValidate
          onSubmit={handleSubmit}
          className="max-w-[380px] w-full flex flex-col items-center gap-y-6"
        >
          <PhoneInput
            label="Escalation Phone No"
            setValue={setFieldValue}
            defaultValue={selectedEscalationNumber?.phone_number}
            name="phone_number"
            className="w-full"
            readOnly={
              selectedEscalationNumber &&
              selectedEscalationNumber.id !== undefined
            }
            error={touched.phone_number && errors.phone_number}
          />
          {(!selectedEscalationNumber || !selectedEscalationNumber.id) && (
            <Button
              title="Save"
              className="w-[237px] h-[44px] bg-button"
              type="submit"
              isLoading={isLoading}
            />
          )}
          {showVerifyOTP && (
            <Button
              title="Verify validation code"
              className="w-[237px] h-[44px] bg-button"
              onClick={() => {
                if (!isShowOTP) setIsShowOTP(true);
              }}
            />
          )}
        </form>
        {selectedEscalationNumber &&
          selectedEscalationNumber.id &&
          isShowOTP && (
            <VerifyOTP
              phone_number={selectedEscalationNumber.phone_number}
              id={selectedEscalationNumber.id}
              submitHandler={verifyEscalationNumber}
              resendOTP={resendOTP}
              setIsResendOtpButtonDisabled={setIsResendOtpButtonDisabled}
              isResendOtpButtonDisabled={isResendOtpButtonDisabled}
            />
          )}
      </Modal>
    </Fragment>
  );
}

export default EscalationNumber;
