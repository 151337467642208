import { AxiosError } from "axios";
import { toast } from "react-toastify";

export const handleServerError = (error: AxiosError | unknown) => {
  if (error instanceof AxiosError && error.response) {
    const errorMessage = Object.values(
      error.response?.data as { [key: string]: string }
    )[0].toString();
    toast.error(errorMessage);
  } else toast.error("Some error has occured! Please try again.");
};
