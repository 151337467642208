export enum LOADINGSTATE {
  AUTH = "auth",
  USERMANAGEMENT = "user-management",
  ESCALATIONNUMBER = "escalation-number",
  VERIFYOTP = "verify-otp",
  RESENDOTP = "resend-otp",
  ALARMS = "alarms",
  IMAGES = "images",
}
export type TLoadingSlice = {
  isLoading: { [key in LOADINGSTATE]: boolean };
};
