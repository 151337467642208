import React, { Fragment } from "react";
import AlarmScheduleCard from "./AlarmScheduleCard";
import { TAlarm } from "state/dashboard/types";
import { MODALS, useModal } from "context/Modal";
import { getDay, getDayPeriod } from "utils/alarm";

type TProps = {
  alarms: TAlarm[];
  setSelectedAlarm: (payload: TAlarm) => void;
};
function ScheduledAlarmsPanel({ alarms, setSelectedAlarm }: TProps) {
  const { handleOpen } = useModal();

  return (
    <Fragment>
      <div
        className={`flex flex-col gap-y-5 ${alarms.length === 0 && "hidden"}`}
      >
        {alarms.map(({ day, time, id }) => (
          <AlarmScheduleCard
            key={id}
            day={getDay(day)}
            time={time}
            dayPeriod={getDayPeriod(time)}
            onClick={() => {
              setSelectedAlarm({ day, time, id });
              handleOpen(MODALS.UPDATEALARM);
            }}
          />
        ))}
      </div>
    </Fragment>
  );
}

export default ScheduledAlarmsPanel;
