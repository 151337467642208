import React from "react";
import Form from "./components/Form";
import AuthLayout from "components/Layouts/Auth";
import { useAuth } from "hooks/auth/useAuth";
import { Link } from "react-router-dom";
import { PATHS } from "constants/paths";

function ForgotPassword() {
  const { forgotPassword, isLoading } = useAuth();
  return (
    <AuthLayout>
      <h1 className="text-main text-[32px] font-semibold my-4 mobile:my-2">
        Welcome Dudes
      </h1>
      <Form submitHandler={forgotPassword} isLoading={isLoading.auth} />
      <Link
        className="text-link mt-3 mobile:mt-2 hover:underline"
        to={PATHS.LOGIN}
      >
        {" "}
        Back to Login
      </Link>
    </AuthLayout>
  );
}

export default ForgotPassword;
