import { string } from "yup";

const password_schema = string()
  .required("Password is required")
  .min(8, "Password must be at least 8 characters long")
  .max(20, "Password must be between 8 and 20 characters")
  .test(
    "containsLowerCase",
    "Password must contain at least one lowercase letter",
    (value) => /[a-z]/.test(value)
  )
  .test(
    "containsUpperCase",
    "Password must contain at least one uppercase letter",
    (value) => /[A-Z]/.test(value)
  )
  .test("containsDigit", "Password must contain at least one digit", (value) =>
    /\d/.test(value)
  )
  .test(
    "containsSpecialChar",
    "Password must contain at least one special character (@$!%*?&)",
    (value) => /[@$!%*?&]/.test(value)
  );
export default password_schema;
