import Form from "./components/Form";
import AuthLayout from "components/Layouts/Auth";
import { PATHS } from "constants/paths";
import { useAuth } from "hooks/auth/useAuth";
import { Link } from "react-router-dom";
import { useAuthState } from "state/auth/hooks";

function ResetPassword() {
  const { resetPassword, isLoading } = useAuth();
  const { phone_number } = useAuthState();
  return (
    <AuthLayout>
      <h1 className="text-main text-[32px] font-semibold my-4 mobile:my-2">
        Welcome Dudes
      </h1>
      <Form
        submitHandler={resetPassword}
        phone_number={phone_number}
        isLoading={isLoading.auth}
      />
      <Link
        className="text-link mt-3 mobile:mt-2 hover:underline"
        to={PATHS.LOGIN}
      >
        {" "}
        Back to Login
      </Link>
    </AuthLayout>
  );
}

export default ResetPassword;
