import React from "react";
import Button from "components/Button";
import Input from "components/Input";
import PhoneInput from "components/Input/PhoneNumber";
import { useFormik } from "formik";
import { registrationSchema } from "schema/user-management";
import { REGISTRATIONINITIALVALUES } from "constants/form";
import { TCreateUserPayload } from "types/user-management";

type TProps = {
  submitHandler: (arg: TCreateUserPayload) => Promise<void>;
  isLoading: boolean;
};
function Form({ submitHandler, isLoading }: TProps) {
  const { handleSubmit, handleChange, values, touched, errors, setFieldValue } =
    useFormik({
      initialValues: REGISTRATIONINITIALVALUES,
      validationSchema: registrationSchema,

      onSubmit: async (values, { setSubmitting }) => {
        await submitHandler(values);
        setSubmitting(false);
      },
    });
  return (
    <form
      noValidate
      onSubmit={handleSubmit}
      className="flex flex-col gap-y-4 items-center justify-center w-full max-w-[370px] mobile:gap-y-2"
    >
      <Input
        placeholder="Name"
        className="w-full"
        error={touched.name && errors.name}
        onChange={handleChange}
        value={values.name}
        name="name"
      />
      <PhoneInput
        setValue={setFieldValue}
        name="phone_number"
        className="w-full"
        error={touched.phone_number && errors.phone_number}
      />
      <Input
        placeholder="Password"
        className="w-full"
        error={touched.password && errors.password}
        onChange={handleChange}
        value={values.password}
        name="password"
        type="password"
      />
      <Button
        title="Sign up"
        className="w-[237px] h-[44px] bg-button mt-2"
        type="submit"
        isLoading={isLoading}
      />
    </form>
  );
}

export default Form;
