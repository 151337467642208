import { useCallback } from "react";
import { api } from "config/api";
import { toast } from "react-toastify";
// import { useDashbaord } from "state/dashboard/hooks";
import { useLoading } from "state/loading/hooks";
import { LOADINGSTATE } from "state/loading/types";
import { handleServerError } from "utils/error";

export const useImageService = () => {
  const { setIsLoading } = useLoading();
  // const { setImages } = useDashbaord();

  const getUserSelfies = useCallback(async () => {
    try {
      setIsLoading({ [LOADINGSTATE.IMAGES]: true });
      const { data } = await api.get("alarms/selfees/");
      if (!data) return;
      if (!data.results) return;
      if (!data.results.length) return;
      // console.log("images", data.results);
      //   const alarms: string[] = data.results.map(())
      //   setImages(alarms);
    } catch (e) {
      handleServerError(e);
    } finally {
      setIsLoading({ [LOADINGSTATE.IMAGES]: false });
    }
  }, [setIsLoading]);

  const uploadSelfie = useCallback(
    async (file: File) => {
      try {
        setIsLoading({ [LOADINGSTATE.IMAGES]: true });
        const formData = new FormData();
        formData.append("image", file);
        const { data } = await api.post("alarms/selfees/", formData);
        if (!data) return;
        toast.success("Your image has been uploaded successfully.");
        getUserSelfies();
      } catch (e) {
        handleServerError(e);
      } finally {
        setIsLoading({ [LOADINGSTATE.IMAGES]: false });
      }
    },
    [setIsLoading, getUserSelfies]
  );

  return {
    getUserSelfies,
    uploadSelfie,
  };
};
