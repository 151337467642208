import React, { useCallback, useEffect } from "react";
import Modal from "components/Modal";
import Form from "./Form";
import { MODALS, useModal } from "context/Modal";
import { TAlarm } from "state/dashboard/types";
import { breakdownTime, getDay } from "utils/alarm";
import { TUpdateAlarmPayload } from "types/alarm";
import { useLoading } from "state/loading/hooks";
import { LOADINGSTATE } from "state/loading/types";

type TProps = {
  alarm: TAlarm | undefined;
  setSelectedAlarm: (payload: TAlarm | undefined) => void;
  updateAlarm: (payload: TUpdateAlarmPayload) => Promise<void>;
  deleteAlarm: (id: number) => Promise<void>;
};
function EditAlarmModal({
  alarm,
  setSelectedAlarm,
  updateAlarm,
  deleteAlarm,
}: TProps) {
  const { isModalOpen } = useModal();
  const { isLoading } = useLoading();
  const getFormValues = useCallback((alarm: TAlarm) => {
    const time = breakdownTime(alarm.time);
    return {
      ...time,
      id: alarm.id,
      day: alarm.day,
    };
  }, []);

  //reset the selected alarm once the modal is closed
  useEffect(() => {
    if (isModalOpen.update_alarm) return;
    setSelectedAlarm(undefined);
  }, [isModalOpen.update_alarm, setSelectedAlarm]);

  return (
    <Modal modal={MODALS.UPDATEALARM} className="max-w-[450px]">
      {alarm && (
        <h1 className="typography text-main text-xl mb-3">
          {getDay(alarm.day)}
        </h1>
      )}
      {alarm && (
        <Form
          submitHandler={updateAlarm}
          alarm={getFormValues(alarm)}
          isLoading={isLoading[LOADINGSTATE.ALARMS]}
          deleteAlarm={deleteAlarm}
        />
      )}
    </Modal>
  );
}

export default EditAlarmModal;
